export default {
  state: {
    painAverage: -1,
    painWorst: -1,
    painNow: -1,
    activityChores: -1,
    activityStairs: -1,
    activityWalk: -1,
    activityErrands: -1,
    fearOfMovement: -1,
    productivity: -1,
    confidence: -1,
    phqInterest: -1,
    phqFeeling: -1,
    gadNervous: -1,
    gadControl: -1,
    gadWorrying: -1,
    gadRelaxing: -1,
    gadRestless: -1,
    gadIrritable: -1,
    gadAfraid: -1,
    selectedPastConsultations: [],
    selectedPastMedications: [],
  },
  getters: {
    selfTestReevaluationsAnswers: (state) =>
      Object.fromEntries(
        [
          ['pain_average', state.painAverage],
          ['pain_worst', state.painWorst],
          ['pain_now', state.painNow],
          ['activity_chores', state.activityChores],
          ['activity_stairs', state.activityStairs],
          ['activity_walk', state.activityWalk],
          ['activity_errands', state.activityErrands],
          ['fear-of-movement', state.fearOfMovement],
          ['productivity', state.productivity],
          ['confidence', state.confidence],
          ['phq-interest', state.phqInterest],
          ['phq-feeling', state.phqFeeling],
          ['gad-nervous', state.gadNervous],
          ['gad-control', state.gadControl],
          ['gad-worrying', state.gadWorrying],
          ['gad-relaxing', state.gadRelaxing],
          ['gad-restless', state.gadRestless],
          ['gad-irritable', state.gadIrritable],
          ['gad-afraid', state.gadAfraid],
          ['past_consultations', state.selectedPastConsultations],
          ['past_medications', state.selectedPastMedications],
        ].filter(
          ([, value]) =>
            // filter out entries that are an array but don't have entries
            (Array.isArray(value) && value.length > 0) ||
            // filter out entries that are a number but are negative
            (Number.isInteger(value) && value > -1),
        ),
      ),
    painAverage: (state) => state.painAverage,
    painWorst: (state) => state.painWorst,
    painNow: (state) => state.painNow,
    activityChores: (state) => state.activityChores,
    activityStairs: (state) => state.activityStairs,
    activityWalk: (state) => state.activityWalk,
    activityErrands: (state) => state.activityErrands,
    fearOfMovement: (state) => state.fearOfMovement,
    productivity: (state) => state.productivity,
    confidence: (state) => state.confidence,
    phqInterest: (state) => state.phqInterest,
    phqFeeling: (state) => state.phqFeeling,
    gadNervous: (state) => state.gadNervous,
    gadControl: (state) => state.gadControl,
    gadWorrying: (state) => state.gadWorrying,
    gadRelaxing: (state) => state.gadRelaxing,
    gadRestless: (state) => state.gadRestless,
    gadIrritable: (state) => state.gadIrritable,
    gadAfraid: (state) => state.gadAfraid,
    selectedPastConsultations: (state) => state.selectedPastConsultations,
    selectedPastMedications: (state) => state.selectedPastMedications,
  },
  mutations: {
    updatePainAverage(state, val) {
      state.painAverage = val
    },
    updatePainWorst(state, val) {
      state.painWorst = val
    },
    updatePainNow(state, val) {
      state.painNow = val
    },
    updateActivityChores(state, val) {
      state.activityChores = val
    },
    updateActivityStairs(state, val) {
      state.activityStairs = val
    },
    updateActivityWalk(state, val) {
      state.activityWalk = val
    },
    updateActivityErrands(state, val) {
      state.activityErrands = val
    },
    updateFearOfMovement(state, val) {
      state.fearOfMovement = val
    },
    updateProductivity(state, val) {
      state.productivity = val
    },
    updateConfidence(state, val) {
      state.confidence = val
    },
    updatePHQInterest(state, val) {
      state.phqInterest = val
    },
    updatePHQFeeling(state, val) {
      state.phqFeeling = val
    },
    updateGADNervous(state, val) {
      state.gadNervous = val
    },
    updateGADControl(state, val) {
      state.gadControl = val
    },
    updateGADWorrying(state, val) {
      state.gadWorrying = val
    },
    updateGADRelaxing(state, val) {
      state.gadRelaxing = val
    },
    updateGADRestless(state, val) {
      state.gadRestless = val
    },
    updateGADIrritable(state, val) {
      state.gadIrritable = val
    },
    updateGADAfraid(state, val) {
      state.gadAfraid = val
    },
    updatePastConsultations(state, val) {
      if (!Array.isArray(val)) return
      state.selectedPastConsultations = val
    },
    updatePastMedications(state, val) {
      if (!Array.isArray(val)) return
      state.selectedPastMedications = val
    },
  },
}
