<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.19228 16.2847C1.57365 16.6669 2.19197 16.6669 2.57334 16.2847L8.71874 10.1266L14.8641 16.2847C15.2455 16.6669 15.8638 16.6669 16.2452 16.2847C16.6266 15.9026 16.6266 15.283 16.2452 14.9008L10.0998 8.7427L16.2452 2.5846C16.6266 2.20244 16.6266 1.58284 16.2452 1.20068C15.8638 0.818523 15.2455 0.818523 14.8641 1.20068L8.71874 7.35878L2.57334 1.20068C2.19197 0.818523 1.57365 0.818523 1.19228 1.20068C0.810907 1.58284 0.810907 2.20244 1.19228 2.5846L7.33767 8.7427L1.19228 14.9008C0.810907 15.283 0.810907 15.9026 1.19228 16.2847Z"
      fill="#0F2D5A"
    />
  </svg>
</template>
