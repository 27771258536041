<template>
  <div class="spinner">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@keyframes spinLoader {
  0% {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes loading {
  0% {
    left: -50%;
    width: 50%;
  }
  20% {
    width: 50%;
  }
  50% {
    width: 150%;
  }
  70% {
    width: 200%;
    left: 50%;
  }
  80% {
    width: 240%;
    left: 120%;
  }
  95% {
    width: 300%;
  }
  to {
    left: 100%;
  }
}
.spinner {
  position: relative;
  color: #777a80;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:before {
    content: '';
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: url(../assets/img/page-progress-spinner-secondary.svg) 50%
      no-repeat white;
    background-size: 30px 30px;
    transition: opacity 0.3s ease;
    animation: spinLoader 0.48s steps(12) infinite;
    margin-bottom: 10px;
  }
}
</style>
