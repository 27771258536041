<template>
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00002 5.68294L14.3492 0.240762C14.7686 -0.118661 15.3999 -0.0700991 15.7593 0.349227C16.1187 0.768553 16.0701 1.39985 15.6508 1.75927L8.65081 7.75927C8.27632 8.08027 7.72372 8.08027 7.34923 7.75927L0.349227 1.75927C-0.0700991 1.39985 -0.118661 0.768553 0.240762 0.349227C0.600184 -0.0700991 1.23148 -0.118661 1.65081 0.240762L8.00002 5.68294Z"
      fill="#0F2D5A"
    />
  </svg>
</template>
