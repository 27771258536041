import types from './types'

const assertType = (value, expectedTypes = []) => {
  let assertionSucceeded = false
  const valueType = typeof value

  switch (valueType) {
    case 'number':
      assertionSucceeded = expectedTypes.includes(types.NUMBER)
      break
    case 'string':
      assertionSucceeded = expectedTypes.includes(types.STRING)
      break
    case 'undefined':
      assertionSucceeded = expectedTypes.includes(types.NULL)
      break
    case 'object':
      if (Array.isArray(value)) {
        assertionSucceeded = expectedTypes.includes(types.ARRAY)
      } else if (value instanceof Date) {
        assertionSucceeded = expectedTypes.includes(types.DATE)
      } else if (value == null) {
        assertionSucceeded = expectedTypes.includes(types.NULL)
      } else if (value.constructor === {}.constructor) {
        assertionSucceeded = expectedTypes.includes(types.JSON)
      } else if (expectedTypes.includes(value.constructor)) {
        assertionSucceeded = true
      }
      break
    case 'boolean':
      assertionSucceeded = expectedTypes.includes(types.BOOLEAN)
      break
    default:
      break
  }

  if (!assertionSucceeded) {
    throw new TypeError(
      `value ${value} is of incorrect type: ${valueType}. Expected type: ${expectedTypes}`,
    )
  }
}

export default assertType
