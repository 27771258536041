<template>
  <div>
    <!-- we need to cache images in advance due to performance reasons -->
    <AssessmentSelfTestAssetsCache_MSK_US />

    <FullPagePreloader v-if="showPreloader" />
    <form
      v-else
      class="assessment-login-content"
    >
      <MobileHeader />
      <div class="content">
        <div class="content-header">
          <p
            class="kaia-p3-bold"
            v-html="$t('b2c_log_in')"
          />
        </div>
        <div
          class="welcome-text"
          v-html="
            $t('assessment_login_description_with_support_email', {
              VUE_APP_SUPPORT_EMAIL: supportEmail,
            })
          "
        ></div>
        <BaseBanner
          v-if="message.show"
          :variant="message.type"
          :title="message.title"
        >
          <p
            class="kaia-p2-bold"
            v-html="$t(message.text)"
          />
        </BaseBanner>
        <div class="assessment-login-form">
          <p
            class="form-group-title kaia-p2-bold"
            v-html="$t('b2b_login_account_block_title')"
          />
          <div class="form-controls inline">
            <EmailField
              :show-errors="showErrors"
              :default-value="email"
              :autofocus="true"
              data-q-a="email"
              @input="handleTextInputChange"
            />
            <PasswordField
              :show-errors="showErrors"
              :default-value="password"
              data-q-a="password"
              @input="handleTextInputChange"
            />
          </div>
          <div class="form-actions">
            <div class="interactive-button-regular">
              <BaseButton
                :text="$t('b2c_log_in')"
                :disabled="loadingButtonState === 'DISABLED'"
                :loading="loadingButtonState === 'PROCESSING'"
                data-q-a="login"
                @click="login"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import env from '@shared/env'
import { createHelpers } from '@shared/vuex-utils'
import { logE } from '@shared/utils'
import BaseBanner from '@shared/components/BaseBanner.vue'
import MobileHeader from '@shared/components/MobileHeader.vue'
import EmailField from '@shared/components/EmailField.vue'
import PasswordField from '@shared/components/PasswordField.vue'
import { sendBrazeEvent } from '@shared/api-endpoints'
import BaseButton from '@shared/components/BaseButton.vue'
import FullPagePreloader from '@shared/components/FullPagePreloader.vue'
import AssessmentSelfTestAssetsCache_MSK_US from '@shared/components/AssessmentSelfTestAssetsCache_MSK_US.vue'

const { mapFields } = createHelpers({
  getterType: 'b2b/getField',
  mutationType: 'b2b/updateField',
})

const defaultMessage = {
  text: '',
  title: '',
  type: 'warning',
  show: false,
  code: null,
}

export default {
  name: 'B2BLogin',
  components: {
    AssessmentSelfTestAssetsCache_MSK_US,
    MobileHeader,
    BaseBanner,
    EmailField,
    PasswordField,
    BaseButton,
    FullPagePreloader,
  },
  props: {
    configureStickyHeader: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      env,
      message: { ...defaultMessage },
      emailState: false,
      passwordState: false,
      showErrors: false,
      showPreloader: true,
      loadingButtonState: 'ACTIVE',
    }
  },
  computed: {
    ...mapFields({
      email: 'formData.email',
      password: 'formData.password',
    }),
    ...mapGetters(['user']),
    supportEmail() {
      return import.meta.env.VITE_SUPPORT_EMAIL
    },
    isFormValid() {
      return this.emailState && this.passwordState
    },
  },
  async created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }

    // skip login if user is/can be authenticated (onetimeauthtoken overwrites current session)
    if ((await this.tryLoginWithOneTimeAuthToken()) || !!this.user) {
      await this.startAssessment()
    } else {
      this.showPreloader = false
    }
  },
  methods: {
    ...mapActions({
      signInRequest: 'login',
      loginWithOneTimeAuthToken: 'loginWithOneTimeAuthToken',
    }),
    ...mapActions('assessment', [
      'openCurrentQuestionnaire',
      'getQuestionnaireConfig',
    ]),
    resetMessage() {
      this.message = { ...defaultMessage }
    },
    async tryLoginWithOneTimeAuthToken() {
      try {
        return (
          this.$route.query.one_time_token &&
          (await this.loginWithOneTimeAuthToken({
            token: this.$route.query.one_time_token,
          })) &&
          !!this.user
        )
      } catch (e) {
        logE(e)
        return false
      }
    },
    login() {
      this.showErrors = false

      if (!this.isFormValid) {
        this.showErrors = true
        return false
      }

      this.loadingButtonState = 'PROCESSING'

      // for testing: on non-prod builds enter 'window.stayLoggedIn = false'
      // into the browsers console before signup/login to force a short session.
      const stayLoggedIn =
        import.meta.env.VITE_MODE === 'prod' || window.stayLoggedIn !== false
      this.signInRequest({
        email: this.email,
        password: this.password,
        stayLoggedIn,
      })
        .then(this.startAssessment)
        .catch(this.showSignInErrorMessage)
    },
    showSignInErrorMessage(error) {
      this.showErrors = false
      this.loadingButtonState = 'ACTIVE'

      if (!error || !error.response) {
        this.message.text = 'b2c_common_error_unknown'
        this.message.type = 'warning'
        this.message.show = true
        return
      }
      this.resetMessage()
      switch (error.response.status) {
        case 403:
          this.message.text = 'b2c_common_error_signin_forbidden'
          this.message.type = 'warning'
          break
        case 401:
          this.message.text = 'b2c_common_error_unauthorized'
          this.message.type = 'warning'
          break
        default:
          this.message.text = 'b2c_common_error_unknown'
          this.message.type = 'warning'
      }
      this.message.show = true
    },
    async startAssessment() {
      await this.getQuestionnaireConfig()
      sendBrazeEvent({
        name: 'milestone_assessment_started',
      })
      this.openCurrentQuestionnaire({ router: this.$router })
    },
    handleTextInputChange({ name, value, state }) {
      switch (name) {
        case 'email':
          this.email = value
          this.emailState = state
          break

        case 'password':
          this.password = value
          this.passwordState = state
          break

        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.assessment-login-content {
  .content-header {
    margin-top: 60px;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .welcome-text {
    margin-bottom: 32px;

    h4,
    p {
      font-size: 17px;
      line-height: 22px;
      color: $input-placeholder-color;
    }

    h4 {
      margin: 0;
    }

    p {
      margin: 0 0 20px;
    }
  }

  .form-group-title {
    margin-bottom: 16px;
  }

  .form-controls {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    margin-right: 16px;

    .form-field {
      flex: 0 0 50%;

      &:nth-child(1) {
        margin-right: 16px;
      }
    }
  }

  .form-actions {
    display: flex;
    flex-direction: row;
    margin-right: 16px;

    > * {
      flex: 0 0 50%;
    }

    .interactive-button-regular {
      margin-right: 16px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .assessment-login-content {
    .welcome-text {
      p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: initial;
      }
    }

    .form-controls {
      &.inline {
        display: block;
        margin-right: 0;

        .form-field {
          flex: 1 1 100%;

          &:nth-child(1) {
            margin-right: 0;
          }
        }
      }
    }

    .form-actions {
      display: block;
      margin-right: 0;

      > * {
        flex: 1 1 100%;
        text-align: start;
        width: 100%;
      }

      .interactive-button-regular,
      .interactive-button-outlined {
        margin-right: 0;
        margin-bottom: 16px;
      }

      p {
        margin-top: 16px;
      }
    }
  }
}
</style>
