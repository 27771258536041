import ROUTE from '@shared/modules/assessment/router/names'
import useAuthStore from '@shared/store/useAuthStore.js'

export default async (routeTo, routeFrom, next) => {
  const authStore = useAuthStore()
  switch (routeTo.name) {
    case ROUTE.ASSESSMENT_LOGIN:
      return next()

    default:
      if (!authStore.isAuthenticated) {
        return next({
          name: ROUTE.ASSESSMENT_LOGIN,
          query: routeTo.query,
        })
      }

      return next()
  }
}
