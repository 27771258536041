import * as apiRequest from '@shared/api-endpoints'
import ROUTE from '@shared/modules/assessment/router/names'
import { logI } from '@shared/utils'
import { sessionStorage } from '@shared/helpers/storage'
import selfTest from './selfTest/index.js'

// this also defines which questionnaires are known
const questionnaireRoutes = {
  self_test_reevaluation: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_AVERAGE,
}

const submitSelfTestReevaluations = (store, { data, action }) =>
  apiRequest.submitSelfTestReevaluations({ ...data }).finally(() => {
    action() // next route
  })

const getQuestionnaireConfig = async ({ commit }) => {
  const getCheckupsResponse = await apiRequest.getCheckups()

  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 7)

  const weekAheadISODate = currentDate.toISOString() // "YYYY-MM-DDTHRS:MIN:SEC.MSZ"

  const checkup = getCheckupsResponse.data.checkups.filter(
    (m) => m.completed_at === null && weekAheadISODate >= m.due,
  )[0]

  if (checkup === undefined) {
    logI('no checkup found for user', getCheckupsResponse.data)
    await commit('setCurrentQuestionnaire', {})
    return
  }

  let uncompletedQuestionnaires = checkup.questionnaires
    .filter(
      (q) =>
        q.completed_at === null &&
        // only known questionnaires
        Object.keys(questionnaireRoutes).includes(q.key),
    )
    .map((q) => q.key)

  if (uncompletedQuestionnaires.length === 0) {
    logI('no questionnaires found for user', getCheckupsResponse.data)
    await commit('setCurrentQuestionnaire', {})
    return
  }

  const completedQuestionnaires = checkup.questionnaires
    .filter(
      (q) =>
        q.completed_at !== null &&
        // only known questionnaires
        Object.keys(questionnaireRoutes).includes(q.key),
    )
    .map((q) => q.key)

  if (
    completedQuestionnaires.length === 0 &&
    uncompletedQuestionnaires.length === 1 &&
    uncompletedQuestionnaires[0] === 'self_test'
  ) {
    uncompletedQuestionnaires = ['self_test']
  }

  await commit('setCurrentQuestionnaire', {
    currentQuestionnaire: completedQuestionnaires.length,
  })
  await commit('setQuestionnaires', {
    questionnaires: [...completedQuestionnaires, ...uncompletedQuestionnaires],
  })
}

const openCurrentQuestionnaire = ({ getters }, { router }) => {
  if (getters.currentQuestionnaireRoute !== undefined) {
    router.push({
      name: getters.currentQuestionnaireRoute,
    })

    if (
      getters.currentQuestionnaireRoute === ROUTE.ASSESSMENT_SELF_TEST_WELCOME
    ) {
      sessionStorage.setItem('assessment:previousQuestionnaire', 'self_test')
    } else {
      sessionStorage.removeItem('assessment:previousQuestionnaire')
    }
  } else {
    apiRequest.sendBrazeEvent({
      name: 'milestone_assessment_done',
    })

    router.push(getters.finishRoute)
  }
}

export default {
  namespaced: true,
  modules: {
    selfTest,
  },
  state: {
    finishRoute: { name: ROUTE.ASSESSMENT_COMPLETION },
    // The reason I need this is because initially we
    // have to display 4 questionnaires in the progress bar;
    questionnaires: [],
    currentQuestionnaire: 0,
  },
  getters: {
    finishRoute(state) {
      return state.finishRoute
    },
    questionnaireCount(state) {
      return state.questionnaires.length
    },
    currentQuestionnaire(state) {
      return state.currentQuestionnaire
    },
    currentQuestionnaireRoute(state) {
      return questionnaireRoutes[
        state.questionnaires[state.currentQuestionnaire]
      ]
    },
  },
  mutations: {
    updateFields(state, { partialAnswers, moduleName }) {
      Object.entries(partialAnswers).forEach(([key, value]) => {
        state[moduleName].answers[key] = value
      })
    },
    setQuestionnaires(state, { questionnaires }) {
      state.questionnaires = questionnaires.filter(
        (q) => questionnaireRoutes[q] != null,
      )
    },
    setCurrentQuestionnaire(state, { currentQuestionnaire }) {
      state.currentQuestionnaire = currentQuestionnaire
    },
  },
  actions: {
    submitSelfTestReevaluations,
    getQuestionnaireConfig,
    openCurrentQuestionnaire,
    async completeQuestionnaire({ commit, dispatch }, { router }) {
      await dispatch('getQuestionnaireConfig', { commit })
      dispatch('openCurrentQuestionnaire', { router })
    },
  },
}
