<template>
  <div class="quiz-content__inner slider-view self-test">
    <div class="self-test__layout">
      <div class="form-container">
        <h3
          class="self-test__header"
          v-html="$t('b2b_self_test_pain_average_question')"
        />
        <div
          class="self-test__description"
          v-html="descriptionText"
        />
      </div>
      <div class="slider-view__slider">
        <ScoreSlider
          :max-score="10"
          :score="getScoreSliderValue"
          :label="getScoreSliderLabel"
          data-q-a="score-slider"
          @change="handleSliderChange"
          @tap="handleSliderChange"
        />
        <div class="slider-view__hints">
          <div
            class="slider-view__hint"
            v-html="$t('b2b_self_test_pain_intensity_no_pain')"
          />
          <div
            class="slider-view__hint"
            v-html="$t('b2b_self_test_pain_intensity_severe_pain')"
          />
        </div>
      </div>
      <div class="interactive-button__layout">
        <BaseButton
          :disabled="interactiveButtonState === 'DISABLED'"
          :text="$t('generic_action_continue')"
          data-q-a="submit"
          @click="handleNextEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseButton from '@shared/components/BaseButton.vue'
import ScoreSlider from '@shared/components/ScoreSlider.vue'

import painLocationDictionary from '@shared/modules/assessment/config/pain-locations.json'
import { useI18n } from 'petite-vue-i18n'

export default {
  name: 'SelfTestPainAverage',
  components: {
    ScoreSlider,
    BaseButton,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      interactiveButtonState: 'DISABLED',
    }
  },
  computed: {
    ...mapGetters('assessment', ['painAverage']),
    ...mapGetters(['user', 'lang']),
    isValid() {
      return this.painAverage >= 0 && this.painAverage <= 10
    },
    getScoreSliderValue() {
      return this.painAverage === -1 ? 5 : this.painAverage
    },
    getScoreSliderLabel() {
      return this.painAverage === -1 ? '' : `${this.painAverage}`
    },
    descriptionText() {
      const painLocationValue = this.user.profile.worstPainArea || ''

      return this.t('b2b_self_test_pain_average_description', {
        painLocation: this.painLocationLabel,
        painLocationGenderEnding: painLocationValue === 'hand' ? 'e' : '',
      })
    },
    painLocationLabel() {
      const painLocationLabel =
        painLocationDictionary[this.user.profile.worstPainArea]?.label || ''

      return this.lang.indexOf('en-') === 0
        ? this.t(painLocationLabel).toLowerCase()
        : this.t(painLocationLabel)
    },
  },
  created() {
    this.interactiveButtonState = this.isValid ? 'ACTIVE' : 'DISABLED'
  },
  methods: {
    handleSliderChange({ value }) {
      this.interactiveButtonState = 'ACTIVE'
      this.$store.commit('assessment/updatePainAverage', value)
    },
  },
}
</script>

<style lang="scss">
@import '@shared/modules/assessment/views/css/SelfTest';
@import '@shared/modules/assessment/views/css/SelfTestSliderView';
</style>
