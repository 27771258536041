const getUrlTrackingRecord = (url) => {
  url = typeof url === 'string' ? url : String(url)

  const searchParams = new URLSearchParams(url)
  return {
    record: {
      source: searchParams.get('utm_source') || '',
      medium: searchParams.get('utm_medium') || '',
      campaign: searchParams.get('utm_campaign') || '',
      content: searchParams.get('utm_content') || '',
      term: searchParams.get('utm_term') || '',
    },
  }
}

export default getUrlTrackingRecord
