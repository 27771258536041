<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3128 19.4274V14.8958H7.46898C7.71344 15.8539 8.04223 16.7043 8.42808 17.4117C9.05252 18.5565 9.72225 19.1673 10.3128 19.4274ZM11.8128 19.3677V14.8958H14.5317C14.2872 15.8539 13.9584 16.7043 13.5726 17.4117C12.9918 18.4764 12.3718 19.0792 11.8128 19.3677ZM11.8128 13.3958H14.8297C14.9401 12.6392 15.0003 11.8363 15.0003 11C15.0003 10.1636 14.9401 9.3607 14.8297 8.60413L11.8128 8.60413V13.3958ZM11.8128 7.10413V2.63227C12.3718 2.92068 12.9918 3.52349 13.5726 4.58824C13.9584 5.29565 14.2872 6.14599 14.5317 7.10413L11.8128 7.10413ZM16.3441 8.60413C16.4462 9.37205 16.5003 10.1744 16.5003 11C16.5003 11.8255 16.4462 12.6279 16.3441 13.3958H19.2448C19.4654 12.6354 19.5837 11.8315 19.5837 11C19.5837 10.1684 19.4654 9.36452 19.2448 8.60413L16.3441 8.60413ZM18.6507 7.10413L16.0748 7.10413C15.7176 5.5423 15.1522 4.18018 14.4421 3.13455C16.2617 3.9319 17.7516 5.34214 18.6507 7.10413ZM10.3128 7.10413L7.46898 7.10412C7.71344 6.14599 8.04223 5.29565 8.42808 4.58824C9.05252 3.44344 9.72225 2.83266 10.3128 2.57249V7.10413ZM7.17094 8.60412L10.3128 8.60413V13.3958H7.17094C7.06054 12.6392 7.00033 11.8363 7.00033 11C7.00033 10.1636 7.06054 9.3607 7.17094 8.60412ZM5.92587 7.10412C6.28304 5.5423 6.84848 4.18018 7.5585 3.13455C5.73894 3.9319 4.24906 5.34214 3.35 7.10412L5.92587 7.10412ZM2.75582 8.60412L5.65652 8.60412C5.55444 9.37205 5.50033 10.1744 5.50033 11C5.50033 11.8255 5.55444 12.6279 5.65652 13.3958H2.75582C2.53523 12.6354 2.41699 11.8315 2.41699 11C2.41699 10.1684 2.53523 9.36452 2.75582 8.60412ZM14.4421 18.8654C15.1522 17.8197 15.7176 16.4576 16.0748 14.8958H18.6507C17.7516 16.6578 16.2617 18.068 14.4421 18.8654ZM7.5585 18.8654C5.73894 18.068 4.24906 16.6578 3.35 14.8958H5.92587C6.28304 16.4576 6.84848 17.8197 7.5585 18.8654ZM21.0837 11C21.0837 16.5688 16.5692 21.0833 11.0003 21.0833C5.43145 21.0833 0.916992 16.5688 0.916992 11C0.916992 5.43109 5.43145 0.916626 11.0003 0.916626C16.5692 0.916626 21.0837 5.43109 21.0837 11Z"
      fill="#3676C9"
    />
  </svg>
</template>
