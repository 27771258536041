<template>
  <div class="information-media">
    <AppLogo />
    <div class="information-wrapper">
      <h2
        class="information-title"
        v-html="$t('b2b_info_title')"
      />
      <img
        class="information-image"
        src="@shared/assets/img/b2b/info-media.png"
        alt="kaia app"
      />
    </div>
  </div>
</template>

<script>
import AppLogo from '@shared/components/AppLogo.vue'

export default {
  name: 'InformationMedia',
  components: { AppLogo },
}
</script>

<style lang="scss">
.information-media {
  background-color: $sky;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 60px;
  justify-content: space-between;
  min-height: 100vh;

  .information-wrapper {
    overflow: hidden;
    padding-bottom: 100px;
  }

  .information-title {
    font-weight: $font-weight-bold;
  }

  .information-image {
    width: 100%;
    margin: 30px auto -15%;
    max-width: 574px;
  }
}
</style>
