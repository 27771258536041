<template>
  <transition
    name="vertical-show-hide"
    @enter="enter"
    @leave="leave"
  >
    <div
      v-if="visible"
      class="vertical-show-hide__inner"
    >
      <slot />
    </div>
  </transition>
</template>
<script setup>
defineProps({
  visible: Boolean,
})

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px'
}

const leave = (el) => {
  el.style.height = '0'
}
</script>

<style lang="scss">
.vertical-show-hide {
  &-enter-active,
  &-leave-active {
    // give it a bit of a delay so that clicks are still executed with the old bounding boxes
    transition: height 0.2s ease-in-out 0.1s;
  }

  &-enter-from {
    height: 0;
  }

  &__inner {
    overflow: hidden;
  }
}
</style>
