<template>
  <div class="content">
    <h2 v-html="$t('b2b_acknowledgements_page_title')" />

    <p v-html="$t('b2b_acknowledgements_page_intro')" />

    <ul class="libraries">
      <li
        v-for="(license, name, index) in licenses"
        :key="index"
      >
        <h3 class="name">
          {{ name }}
        </h3>

        <div v-if="license.publisher">({{ license.publisher }})</div>

        <div>
          {{ license.licenses }}
        </div>
      </li>
    </ul>

    <p>
      This site or product includes IP2Location LITE data available from
      <a href="https://lite.ip2location.com">https://lite.ip2location.com</a>.
    </p>
  </div>
</template>

<script>
import licenses from '@shared/license-list.json'

export default {
  name: 'Acknowledgements',
  data() {
    return {
      licenses,
    }
  },
}
</script>

<style lang="scss" scoped>
.libraries {
  padding-left: 1.5em;

  p,
  li {
    line-height: 2em;
    color: $gray-50;
    margin-bottom: 1.5em;

    @media (max-width: $breakpoint-tablet) {
      font-size: 14px;
      line-height: 20px;
    }

    .name {
      margin: 0;
      word-break: break-all;
    }
  }
}
</style>
