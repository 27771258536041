<template>
  <div class="b2b-media">
    <AppLogo>
      {{ $t('b2b_logo_text') }}
    </AppLogo>
    <div class="b2b-video-width-container">
      <div :class="`b2b-video-container ${videoOrientation}`">
        <img src="@shared/assets/img/b2b/iphone8_frame.png" />
        <video
          ref="video"
          class="device-video"
          src="@shared/assets/video/kaia_app_screen_q319.mp4"
          type="video/mp4"
          loop
          autoplay
          muted
          @timeupdate="videoTimeUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppLogo from '@shared/components/AppLogo.vue'

export default {
  name: 'B2bMedia',
  components: { AppLogo },
  data() {
    return {
      videoOrientation: 'portrait',
    }
  },
  methods: {
    videoTimeUpdate() {
      if (!this.$refs.video) {
        return
      }
      let orientation = this.videoOrientation
      const orientationStoryboard = [
        [11, 'landscape'],
        [33, 'portrait'],
      ]
      const currentTime = parseInt(this.$refs.video.currentTime, 10)
      for (let i = 0; i < orientationStoryboard.length; i++) {
        const board = orientationStoryboard[i]
        if (board[0] <= currentTime) {
          orientation = board[1]
        } else {
          break
        }
      }
      this.videoOrientation = orientation
    },
  },
}
</script>

<style lang="scss">
.b2b-media {
  background-color: $sky;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 60px;
  justify-content: space-between;
  min-height: 100vh;

  .b2b-video-width-container {
    margin: 30px auto;
    max-width: 60vh;
    width: 100%;

    .b2b-video-container {
      padding-bottom: 100%;
      position: relative;
      transition: transform 500ms;
      transform: none;
      width: 100%;

      &.landscape {
        transform: rotate(-90deg);
      }

      &.portrait {
        transform: none;
      }

      img {
        bottom: 0;
        left: 0;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        right: 0;
        top: 0;
      }

      .device-video {
        bottom: 0;
        height: 78%;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 78%;
      }
    }
  }
}
</style>
