<template>
  <div class="self-test-navigation">
    <QuizNavHeader
      :stage="stage"
      :stage-count="stageCount"
      :stage-progress="stageProgress"
      :title="title"
      :back-button="backButton"
      :info-text="infoText"
      @btn-back-clicked="handleBackButtonClick"
    />
  </div>
</template>

<script>
import QuizNavHeader from '@shared/components/Quiz/QuizNavHeader.vue'

export default {
  name: 'AssessmentNavigation',
  components: {
    QuizNavHeader,
  },
  props: {
    stage: {
      type: Number,
      default: 0,
    },
    stageCount: {
      type: Number,
      default: 0,
    },
    stageProgress: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    backButton: {
      type: Boolean,
      default: true,
    },
    infoText: {
      type: String,
      default: '',
    },
  },
  emits: ['back'],
  methods: {
    handleBackButtonClick() {
      this.$emit('back')
    },
  },
}
</script>
