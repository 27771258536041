export default (needle, title) => {
  if (typeof needle !== 'string' || typeof title !== 'string') {
    return ''
  }

  if (!needle.length || !title.length) {
    return ''
  }

  // WARN: To correct the regex
  const escapedNeedle = needle.replace(/([[\]{}()/\\^$?:=+\-&><.*%])/g, '\\$1')
  const searchPattern = new RegExp(`(${escapedNeedle})`, 'gi')
  const replacePattern = '<strong>$1</strong>'
  const highlightedTitle = title.replace(searchPattern, replacePattern)

  if (title !== highlightedTitle) {
    return highlightedTitle
  }
  return ''
}
