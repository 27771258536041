export default {
  hwi: {
    corporate: 'harbisonwalker',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-A',
    },
  },
  'harbison-walker': {
    corporate: 'harbisonwalker',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-B2',
    },
  },
  'metlife-family': {
    corporate: 'metlife',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer-2',
    },
  },
  'for-metlife': {
    corporate: 'metlife',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer-3a',
    },
  },
  metlifevip: {
    corporate: 'metlife',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer-5b',
    },
  },
  lynn: {
    corporate: 'cityoflynn',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-1b',
    },
  },
  'hphc-team': {
    corporate: 'hphc',
    query: {
      utm_source: 'poster',
      utm_campaign: 'tv-ad-1',
    },
  },
  'hphc-vip': {
    corporate: 'hphc',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-1b',
    },
  },
  jordans: {
    corporate: 'jordansfurniture',
    query: {
      utm_source: 'poster',
      utm_campaign: 'tv-ad-1',
    },
  },
  'jordans-vip': {
    corporate: 'jordansfurniture',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-1b',
    },
  },
  'dana-farber-vip': {
    corporate: 'dana-farber',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-1b',
    },
  },
  'dana-farber-team': {
    corporate: 'dana-farber',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'activation-2b',
    },
  },
  metlife1: {
    corporate: 'metlife',
    query: {
      utm_source: 'referral',
      utm_campaign: 'case-management-optum',
    },
  },
  met: {
    corporate: 'metlife',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '6a',
    },
  },
  'met-life': {
    corporate: 'metlife',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '6b',
    },
  },
  'hp-team': {
    corporate: 'hphc',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '2a',
    },
  },
  'team-hphc': {
    corporate: 'hphc',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '2b',
    },
  },
  dfci: {
    corporate: 'dana-farber',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '3a',
    },
  },
  'df-institute': {
    corporate: 'dana-farber',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '3b',
    },
  },
  jf: {
    corporate: 'jordansfurniture',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '1a',
    },
  },
  'j-team': {
    corporate: 'jordansfurniture',
    query: {
      utm_source: 'homemailer',
      utm_campaign: '1b',
    },
  },
  'hwi-hh': {
    corporate: 'harbisonwalker',
    query: {
      utm_source: 'referral',
      utm_campaign: 'case-management-highmark',
    },
  },
  lynn1: {
    corporate: 'cityoflynn',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'sept-mailer',
    },
  },
  'city-lynn': {
    corporate: 'cityoflynn',
    query: {
      utm_source: 'poster',
      utm_campaign: 'sept-poster',
    },
  },
  alstom1: {
    corporate: 'alstom',
    query: {
      utm_source: 'referral',
      utm_campaign: 'consumermedical',
    },
  },
  hwi1: {
    corporate: 'harbisonwalker',
    query: {
      utm_source: 'nudge-cards',
      utm_campaign: 'nudge-cards-1',
    },
  },
  fairway1: {
    corporate: 'fairway',
    query: {
      utm_source: 'poster',
      utm_campaign: 'oe-poster',
    },
  },
  pebb1: {
    corporate: 'pebb',
    query: {
      utm_source: 'referral',
      utm_campaign: 'medical-home-referral',
    },
  },
  saif1: {
    corporate: 'saif',
    query: {
      utm_source: 'referral',
      utm_campaign: 'medical-home-referral',
    },
  },
  jf2: {
    corporate: 'jordansfurniture',
    query: {
      utm_source: 'poster',
      utm_campaign: 'oct-banner',
    },
  },
  hwihm3: {
    corporate: 'harbisonwalker',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer',
    },
  },
  hphchm3: {
    corporate: 'hphc',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer',
    },
  },
  cityoflynnhm3: {
    corporate: 'cityoflynn',
    query: {
      utm_source: 'homemailer',
      utm_campaign: 'homemailer',
    },
  },
  jacobs: {
    corporate: 'jacobsengineering',
    query: {
      utm_campaign: 'shortlink',
    },
  },
  surest: {
    corporate: 'bind',
  },
  go: {
    corporate: 'uhc',
    query: {
      utm_medium: 'partner-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-05-en-ac-UHC-postcard',
      utm_content: 'dowhatyoulove-dowhatyoulove-shorturl',
    },
  },
  qorvo1: {
    corporate: 'qorvo',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-qorvo',
      utm_content: 'A-varA-printlink',
    },
  },
  qorvo2: {
    corporate: 'qorvo',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-qorvo',
      utm_content: 'B-varB-printlink',
    },
  },
  qorvo3: {
    corporate: 'qorvo',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-qorvo',
      utm_content: 'C-varC-printlink',
    },
  },
  mps1: {
    corporate: 'milwaukeepublicschools',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-MPS',
      utm_content: 'A-varA-printlink',
    },
  },
  mps2: {
    corporate: 'milwaukeepublicschools',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-MPS',
      utm_content: 'B-varB-printlink',
    },
  },
  mps3: {
    corporate: 'milwaukeepublicschools',
    query: {
      utm_medium: 'kaia-mail',
      utm_source: 'postcard',
      utm_campaign: '2024-10-en-ac-MPS',
      utm_content: 'C-varC-printlink',
    },
  },
}
