<template>
  <button
    class="store-badge-button"
    :class="[appType]"
    type="button"
    @click.stop="handleTriggerClick()"
  >
    <AssetImage
      class="cursor-pointer"
      :name="triggerImage"
    />
  </button>
</template>

<script setup>
import { computed } from 'vue'
import AssetImage from '@shared/components/AssetImage.vue'

const props = defineProps({
  appType: {
    type: String,
    required: true,
  },
  lang: {
    type: String,
    required: true,
  },
  redirectOnClick: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['click'])

const triggerImage = computed(() => {
  const langEnum = {
    ios: {
      'en-US': 'app_store_en.svg',
      'de-DE': 'app_store_de.svg',
      'es-US': 'app_store_es.svg',
    },
    android: {
      'en-US': 'play_store_en.svg',
      'de-DE': 'play_store_de.svg',
      'es-US': 'play_store_es.svg',
    },
  }

  return langEnum[props.appType]?.[props.lang]
})

const triggerLink = computed(() => {
  const linkEnum = {
    ios: {
      'en-US': import.meta.env.VITE_LINK_APP_STORE_DEFAULT,
      'de-DE': import.meta.env.VITE_LINK_APP_STORE_DE,
      'es-US': import.meta.env.VITE_LINK_APP_STORE_ES,
    },
    android: {
      'en-US': import.meta.env.VITE_LINK_GOOGLE_PLAY_DEFAULT,
      'de-DE': import.meta.env.VITE_LINK_GOOGLE_PLAY_DE,
      'es-US': import.meta.env.VITE_LINK_GOOGLE_PLAY_ES,
    },
  }

  return linkEnum[props.appType]?.[props.lang]
})

const handleTriggerClick = () => {
  emit('click')
  if (props.redirectOnClick) {
    window.open(triggerLink.value, '_blank')
  }
}
</script>

<style lang="scss" scoped>
.store-badge-button {
  @extend %no-style-button;
}
</style>
