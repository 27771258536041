const types = {
  STRING: 'string',
  NULL: 'undefined',
  NUMBER: 'integer',
  ARRAY: 'array',
  JSON: 'json',
  BOOLEAN: 'boolean',
  DATE: 'date',
}

export default types
