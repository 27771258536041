<template>
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <CorporateHeader />
      <div class="kds-content-block">
        <p class="kds-typography-display-small">
          {{ $t('b2b_verify_not_possible_for_existing_user_title') }}
        </p>
        <p class="kds-typography-paragraph-large">
          {{ $t('b2b_verify_not_possible_for_existing_user_message') }}
        </p>
      </div>
      <div class="verify-not-possible-for-existing-user-actions"></div>
    </div>
    <template #actions>
      <BaseButton
        :text="$t('b2b_verify_not_possible_for_existing_user_action_signup')"
        @click="openSignUp"
      />
    </template>
  </ResponsiveLayout>
</template>

<script setup>
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import ROUTE from '@msk-us/router/appModule/names'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const openSignUp = () => {
  router.push({
    name: ROUTE.B2B_SIGN_UP,
    query: {
      ...this.verificationParams,
      corp_name: this.corporate.key,
      login: 'false',
    },
  })
}
</script>

<style lang="scss"></style>
