<template>
  <div
    v-if="showPageLoader"
    class="page-loader page-loader--start"
  >
    <div class="page-loader__bar"></div>
  </div>
</template>

<script setup>
import { computed, onUnmounted, ref } from 'vue'
import { PublicApi, PrivateApi } from '@shared/api-client'

const currentRequests = ref([])

function addRequest() {
  const requestId =
    Date.now().toString(36) + Math.random().toString(36).substring(2, 7)
  currentRequests.value.push(requestId)
  return requestId
}

function removeRequest(requestId) {
  currentRequests.value = currentRequests.value.filter(
    (item) => item !== requestId,
  )
}

const handleRequestFulfilled = (config) => {
  const requestId = addRequest()
  config.metadata = { ...config.metadata, requestId }
  return config
}

const handleRequestRejected = (error) => {
  removeRequest(error.config.metadata?.requestId)
  return Promise.reject(error)
}

const handleResponseFulfilled = (response) => {
  removeRequest(response?.config?.metadata?.requestId)
  return response
}

const handleResponseRejected = (error) => {
  removeRequest(error?.config?.metadata?.requestId)
  return Promise.reject(error)
}

const publicApiRequestInterceptor = PublicApi.interceptors.request.use(
  handleRequestFulfilled,
  handleRequestRejected,
)
const publicApiResponseInterceptor = PublicApi.interceptors.response.use(
  handleResponseFulfilled,
  handleResponseRejected,
)
const privateApiRequestInterceptor = PrivateApi.interceptors.request.use(
  handleRequestFulfilled,
  handleRequestRejected,
)
const privateApiResponseInterceptor = PrivateApi.interceptors.response.use(
  handleResponseFulfilled,
  handleResponseRejected,
)

const showPageLoader = computed(() => currentRequests.value.length > 0)

onUnmounted(() => {
  PublicApi.interceptors.request.eject(publicApiRequestInterceptor)
  PublicApi.interceptors.response.eject(publicApiResponseInterceptor)
  PrivateApi.interceptors.request.eject(privateApiRequestInterceptor)
  PrivateApi.interceptors.response.eject(privateApiResponseInterceptor)
})
</script>

<style lang="scss">
@keyframes spinLoader {
  0% {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes loading {
  0% {
    left: -50%;
    width: 50%;
  }
  20% {
    width: 50%;
  }
  50% {
    width: 150%;
  }
  70% {
    width: 200%;
    left: 50%;
  }
  80% {
    width: 240%;
    left: 120%;
  }
  95% {
    width: 300%;
  }
  to {
    left: 100%;
  }
}

.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  z-index: 1110;
  display: block;
  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 15px;
    height: 15px;
    z-index: 2;
    // background: url(../assets/img/page-progress-spinner.svg) 50% no-repeat;
    background-size: 100% auto;
    transition: opacity 0.3s ease;
    animation: spinLoader 0.48s steps(12) infinite;
  }
  &__bar {
    position: absolute;
    top: 0;
    left: -50%;
    height: 100%;
    width: 50%;
    background: $primary;
    z-index: 2;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      border-radius: 50% 1px 1px 50%;
      box-shadow: 0 0 2px $primary;
    }
  }
  &--start {
    display: block;
    .page-loader__bar {
      animation: loading 3s linear forwards infinite;
    }
  }
}
</style>
