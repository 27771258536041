<template>
  <img
    class="asset-image"
    :src="image"
    :alt="altText"
  />
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  altText: {
    type: String,
    default: '',
  },
})

const image = computed(
  () => new URL(`../assets/img/${props.name}`, import.meta.url).href,
)
</script>

<style lang="scss">
/**
  * INFO: Create a filter here: https://isotropic.co/tool/hex-color-to-css-filter/
 **/
:root {
  --asset-image--filter: none;
  --asset-image--height: none;
  --asset-image--width: none;
}

.asset-image {
  filter: var(--asset-image--filter);
  height: var(--asset-image--height);
  width: var(--asset-image--width);
}
</style>
