<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="footer">
    <div class="footer__company">
      <div
        class="footer--label"
        v-html="$t('b2b_static_contacts_name')"
      />
      <span
        class="footer__company--address"
        v-html="staticCompanyAddress"
      />
    </div>
    <div class="footer__contact">
      <div
        class="footer--label"
        v-html="$t('b2b_static_contacts_email_label')"
      />
      <a
        :href="`mailto:${mailto}`"
        class="footer-contacts__link"
      >
        {{ mailto }}
      </a>
    </div>
    <div class="footer__acknowledgements">
      <div
        class="footer--label"
        v-html="$t('b2b_static_acknowledgements_label')"
      />
      <BaseModal
        can-click-outside
        :width="1000"
      >
        <template #trigger="{ open }">
          <a
            href="#"
            class="footer__acknowledgements--link"
            @click.prevent="open"
          >
            {{ $t('b2b_static_acknowledgements_link_caption') }}
          </a>
        </template>
        <Acknowledgements />
      </BaseModal>
    </div>
    <div class="footer__language">
      <LanguageSwitcher />
    </div>
    <div
      v-if="corporate?.hasDisclaimer"
      class="footer__legal"
    >
      <div
        class="footer--label"
        v-html="$t('b2b_static_legal_label')"
      />
      <div class="footer__legal--list">
        <DocumentViewer
          v-if="corporate?.kaiaTermsEnabled"
          :link="aTagWrapper('b2b_static_terms_conditions_link', 'terms')"
        ></DocumentViewer>
        <DocumentViewer
          v-if="corporate?.kaiaTermsEnabled"
          :link="aTagWrapper('b2b_static_privacy_policy_link', 'privacy')"
        ></DocumentViewer>
        <DocumentViewer v-if="corporate?.hasDisclaimerText">
          <template #default="{ viewDocument }">
            <a
              href="#"
              @click.prevent="viewDocument"
            >
              {{ $t('b2b_static_disclaimer_link') }}
            </a>
          </template>
          <template #iframe>
            {{ corporate.disclaimerText }}
          </template>
        </DocumentViewer>
      </div>
    </div>
    <div class="footer__certificates">
      <div
        class="footer--label"
        v-html="$t('b2b_static_certificates_title')"
      />
      <div class="footer__certificates--list">
        <div
          class="footer__certificates--list__medical-device"
          v-html="$t('b2b_static_certificates_registered_medical_device')"
        />
        <div
          class="footer__certificates--list__tuev"
          v-html="$t('b2b_static_certificates_iso_13485')"
        />
        <div
          class="footer__certificates--list__gdpr"
          v-html="$t('b2b_static_certificates_gdpr')"
        />
        <div
          class="footer__certificates--list__hipaa"
          v-html="$t('b2b_static_certificates_hipaa')"
        />
      </div>
    </div>
  </div>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { useI18n } from 'petite-vue-i18n'

import defineLanguage from '@shared/utils/defineLanguage'

import Acknowledgements from '@shared/components/Acknowledgements.vue'
import BaseModal from '@shared/components/BaseModal.vue'
import LanguageSwitcher from '@shared/components/LanguageSwitcher.vue'
import DocumentViewer from '@shared/components/DocumentViewer.vue'

const { t } = useI18n()
const props = defineProps({
  staticCompanyAddress: {
    type: String,
    default: '',
  },
  corporate: {
    type: Object,
    default: () => null,
  },
  lang: {
    type: String,
    required: true,
  },
})

const mailto = import.meta.env.VITE_SUPPORT_EMAIL

const aTagWrapper = (translation, type) => {
  const definedLang = defineLanguage(props.lang)
  const documentLang =
    import.meta.env.VITE_GEO === 'us'
      ? definedLang === 'es'
        ? definedLang
        : 'us'
      : definedLang
  const digaPart = props.digaContext ? '' : '?diga=false'
  const base = import.meta.env.VITE_PUBLIC_PATH

  return `
        <a
          href="#"
          data-custom="false"
          data-link="${base}static/${documentLang}/${type}/index.html${digaPart}"
        >
            ${t(translation)}
        </a>`
}
</script>

<style lang="scss">
.footer {
  background-color: $white;
  padding: 59px 24px;

  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  @include media-up(lg) {
    padding: 59px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'company legal certificates'
      'contact legal certificates'
      'acknowledgements . certificates'
      'language . .';
  }

  &--label {
    padding-bottom: 9px;
    @include kaia-typography-p2($font-weight-bold);
  }

  &__company {
    grid-area: company;

    &--address {
      color: $gray-50;
      @include kaia-typography-p1($font-weight-normal);
    }
  }

  &__contact {
    grid-area: contact;

    a {
      color: $ocean;
      text-decoration: none;

      @include kaia-typography-p1($font-weight-normal);
    }
  }

  &__acknowledgements {
    grid-area: acknowledgements;

    &--link {
      color: $ocean;
      text-decoration: none;

      @include kaia-typography-p1($font-weight-normal);
    }
  }

  &__language {
    grid-area: language;
  }

  &__legal {
    grid-area: legal;

    &--list {
      @include flex-container(column, flex-start, md);

      a {
        color: $ocean;
        text-decoration: none;

        @include kaia-typography-p1($font-weight-normal);
      }
    }
  }

  &__certificates {
    grid-area: certificates;
    &--list {
      @include flex-container(column, flex-start, sm);

      %item {
        padding: 11px 0 11px 46px;
        display: flex;
        align-items: center;

        @include kaia-typography-p1($font-weight-normal);
      }

      &__medical-device {
        @extend %item;

        height: 25px;
        background: url('@shared/assets/img/b2b/icon-ce.svg') left center
          no-repeat;
      }

      &__tuev {
        @extend %item;

        height: 32px;
        background: url('@shared/assets/img/b2b/icon-check-figure.svg') left
          center no-repeat;
      }

      &__gdpr {
        @extend %item;

        height: 33px;
        background: url('@shared/assets/img/b2b/icon-security.svg') left center
          no-repeat;
      }

      &__hipaa {
        @extend %item;

        height: 33px;
        background: url('@shared/assets/img/b2b/icon-health.svg') left center
          no-repeat;
      }
    }
  }
}
</style>
