<template>
  <div style="display: none">
    <img src="@shared/assets/img/b2b/icon-info-midnight.svg" />
    <img src="@shared/assets/img/radial-background.svg" />
    <img src="@shared/assets/img/pain-assessment-intro-background.svg" />
    <img src="@shared/assets/img/checkbox-ocean.svg" />
    <img src="@shared/assets/img/score-slider-knob.svg" />
    <img src="@shared/assets/img/score-slider-scale_red.svg" />
    <img src="@shared/assets/img/score-slider-scale_green.svg" />
    <img src="@shared/assets/img/short_right_arrow.svg" />
    <img src="@shared/assets/img/medical-magnifier-icon.svg" />
    <img src="@shared/assets/img/halfway-update-background.svg" />
    <img src="@shared/assets/img/personalized-therapy-intro-background.svg" />
    <img src="@shared/assets/img/user-interest-background.svg" />
    <img src="@shared/assets/img/typical-day-background.svg" />
    <img src="@shared/assets/img/past-self-treatment-background.svg" />
  </div>
</template>

<script>
export default {
  name: 'AssessmentSelfTestAssetsCacheMSKUS',
}
</script>
