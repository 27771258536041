<template>
  <!-- eslint-disable vue/no-v-html -->
  <ResponsiveLayout>
    <div class="kds-main-wrapper solera-verification">
      <CorporateHeader />
      <Spinner v-if="loading" />
      <BaseBanner
        v-if="showInfoBanner"
        variant="info"
        class="solera-verification__info-banner"
        scroll-into-view
      >
        <h1 class="solera-verification__info-banner--title">
          {{
            $t('b2b__verification__solera__lookupkey_missing__section1_title')
          }}
        </h1>
        <p
          class="solera-verification__info-banner--description"
          v-html="
            $t('b2b__verification__solera__lookupkey_missing__section1_body')
          "
        ></p>
        <h1 class="solera-verification__info-banner--title">
          {{
            $t('b2b__verification__solera__lookupkey_missing__section2_title')
          }}
        </h1>
        <p
          class="solera-verification__info-banner--description"
          v-html="
            $t('b2b__verification__solera__lookupkey_missing__section2_body')
          "
        ></p>
      </BaseBanner>
      <BaseBanner
        v-if="showNotEligibleBanner"
        variant="warning"
        scroll-into-view
      >
        <p
          v-html="
            $t('b2b_corporate_eligibility_data_not_valid_with_solera_dashboard')
          "
        ></p>
      </BaseBanner>
      <AlreadyHaveAccount />
    </div>
  </ResponsiveLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import ROUTE from '@msk-us/router/appModule/names'

import BaseBanner from '@shared/components/BaseBanner.vue'
import Spinner from '@shared/components/Spinner.vue'
import AlreadyHaveAccount from '@msk-us/components/AlreadyHaveAccount.vue'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()
const corporate = computed(() => store.getters['b2b/corporate'])

const loading = ref(false)
const showNotEligibleBanner = ref(false)
const showInfoBanner = ref(false)

const verificationParams = computed(
  () => store.getters['b2b/verificationParams'],
)

const getVerificationPayload = computed(() => {
  return {
    solera_lookup_key:
      route.query.lookupKey || verificationParams.value.lookupKey || '',
    corporate_key: corporate.value.key,
  }
})

onMounted(async () => {
  if (
    !route.query?.lookupKey &&
    !getVerificationPayload.value.solera_lookup_key
  ) {
    showInfoBanner.value = true
    return
  }

  loading.value = true

  try {
    await store.dispatch(
      'b2b/checkCorporateEligibility',
      getVerificationPayload.value,
    )

    router.push({
      name: ROUTE.B2B_SIGN_UP,
      params: {
        corp_name: corporate.value.key,
      },
    })
  } catch (e) {
    const { response } = e || {}
    const { data, status } = response || {}
    const { errors } = data || {}

    if (status === 403) {
      if (errors?.includes('NOT_ELIGIBLE')) {
        showInfoBanner.value = true
      } else if (errors?.includes('ALREADY_USED')) {
        showInfoBanner.value = true
      } else {
        showNotEligibleBanner.value = true
      }
    } else {
      showNotEligibleBanner.value = true
    }
  } finally {
    loading.value = false
  }
})
</script>

<style lang="scss">
.solera-verification {
  &__info-banner {
    &--title {
      @include kaia-typography-p2(bold);
    }
  }
}
</style>
