<template>
  <!-- eslint-disable vue/no-v-html -->
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <CorporateHeader />
      <Spinner v-if="loading" />
      <BaseBanner
        v-if="showAlreadyUsedBanner"
        variant="info"
        :title="$t('b2b__verification__already_used_error_title')"
        scroll-into-view
      >
        <p
          class="kds-typography-paragraph-medium"
          v-html="$t('b2b__verification__already_used_error_description')"
        />
        <template #actions>
          <BaseButton
            size="small"
            @click="goToAppLogin"
          >
            {{ $t('b2b__verification__already_used_error_cta_open_app') }}
          </BaseButton>

          <GenericSupportModal
            :subject="$t('b2b__support_modal_subject__login_support')"
          />
        </template>
      </BaseBanner>

      <BaseBanner
        v-if="showGenericErrorBanner"
        variant="warning"
        :title="$t('b2b__verification__warning_header')"
        scroll-into-view
      >
        <p
          class="kds-typography-paragraph-large"
          v-html="$t('b2b__uhchub_verification__description')"
        />
        <template #actions>
          <GenericSupportModal
            :subject="$t('b2b__support_modal_subject__login_support')"
          />
        </template>
      </BaseBanner>
    </div>
  </ResponsiveLayout>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import ROUTE from '@msk-us/router/appModule/names'

import BaseBanner from '@shared/components/BaseBanner.vue'
import Spinner from '@shared/components/Spinner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import GenericSupportModal from '@msk-us/components/GenericSupportModal.vue'
import Tracker from '@shared/Tracker.js'
import { logE, logI, logW } from '@shared/utils.js'
import assembleKaiaAppDeepLink from '@shared/helpers/assembleKaiaAppDeepLink.js'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'

const store = useStore()
const router = useRouter()

const loading = ref(false)
const showAlreadyUsedBanner = ref(false)
const showGenericErrorBanner = ref(false)

const corporate = computed(() => store.getters['b2b/corporate'])

const verificationParams = computed(
  () => store.getters['b2b/verificationParams'],
)

const utmData = computed(() => store.getters['b2b/utmData'])

const kaiaSubmissionTrackEvent = (success, errorMessage, eventObj) => {
  const custom_payload = {
    search_success: success,
    corporate_id: corporate.value.id,
    corporate_name: corporate.value.title,
    corporate_type: corporate.value.corporateTypes.toString(),
    ...(corporate.value.channel
      ? { corporate_channel: corporate.value.channel }
      : null),
    ...(errorMessage ? { error_message: errorMessage } : null),
    ...(utmData.value.medium ? { utm_medium: utmData.value.medium } : null),
    ...(utmData.value.source ? { utm_source: utmData.value.source } : null),
    ...(utmData.value.campaign
      ? { utm_campaign: utmData.value.campaign }
      : null),
    ...(utmData.value.content ? { utm_content: utmData.value.content } : null),
  }

  Tracker.trackKaiaEvent(eventObj, {
    custom_payload,
  })
}

const eventObj = {
  event_name: 'c.lfcycl.submit_eligibility_verification',
  app_area: 'onboarding',
  action: 'submit',
  object_type: 'eligibility_verification',
  source: 'client_browser',
  screen_name: 'onb_eligibility_verification',
}

const goToAppLogin = async () => {
  const deepLink = await assembleKaiaAppDeepLink('goto/screen', {
    target: 'login',
  })
  window.location.assign(deepLink)
}

const redirectToNext = () => {
  router.push({
    name: ROUTE.B2B_SIGN_UP,
    params: {
      corp_name: corporate.value.key,
    },
  })
}

const onSubmit = async () => {
  showAlreadyUsedBanner.value = false
  showGenericErrorBanner.value = false

  Tracker.trackKaiaEvent(
    {
      event_name: 'c.lfcycl.select_button',
      app_area: 'onboarding',
      action: 'select',
      object_type: 'button',
      source: 'client_browser',
      screen_name: 'onb_eligibility_verification',
    },
    {
      button_label: 'continue',
    },
  )

  await store.dispatch('b2b/checkEligibilityWithToken', {
    corporateKey: corporate.value.key,
    eligible_token: verificationParams.value.eligible_token,
  })
}

const onSuccess = async () => {
  kaiaSubmissionTrackEvent(true, null, eventObj)
  logI('uhchub verification attempt', 'succeeded')
  redirectToNext()
}

const onError = (error) => {
  kaiaSubmissionTrackEvent(false, error, eventObj)
  switch (error.response.data?.error) {
    case 'ALREADY_USED':
      logI(
        'uhchub verification attempt',
        `soft failed -> proposing login, ${error}`,
      )
      showAlreadyUsedBanner.value = true
      break
    case 'MULTIPLE_MATCHES_BUT_UNIQUE_MATCH_REQUIRED':
    case 'NOT_ELIGIBLE':
      // uhchub handshake was successful, but we could not find them in the eligibility lists
      // these users will get access anyway, and we try to reconcile them manually
      logI(
        'uhchub verification attempt',
        `soft failed -> go to signup anyway, ${error}`,
      )
      redirectToNext()
      break
    case 'TOKEN_BLANK':
    case 'TOKEN_EXPIRED':
    case 'TOKEN_INVALID':
    case 'TOKEN_INCOMPLETE':
      // user is not coming from uhchub (token is blank), or the token not valid
      showGenericErrorBanner.value = true
      logW('uhchub verification attempt', 'failed', error)
      break
    default:
      showGenericErrorBanner.value = true
      logE('uhchub verification attempt', 'failed', error)
      throw error
  }
}

onMounted(async () => {
  try {
    loading.value = true
    await onSubmit()
    await onSuccess()
  } catch (e) {
    loading.value = false
    onError(e)
  }
})
</script>
