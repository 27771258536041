<template>
  <div
    class="language-switcher"
    :class="{ 'language-switcher--disabled': disabled }"
  >
    <span
      v-if="!short"
      class="language-switcher__header"
      >{{ $t('generic_language') }}</span
    >
    <div class="language-switcher__dropdown-layout">
      <DropdownList
        :items-layout-c-s-s-classes="['language-switcher__items-layout']"
        :default-value="language"
        :disabled="disabled"
        :items="dropdownItems"
        :show-chevron="!short"
        @change="handleLanguageChange"
      >
        <template #selected="{ item, open }">
          <BaseButton
            v-if="short"
            variant="ghost"
            size="small"
            @click="open"
          >
            <template #prepend>
              <GlobeIcon />
            </template>
            <p
              class="kds-typography-interactive-medium kds-color-text-interactive-accent"
            >
              {{ item.short }}
            </p>
          </BaseButton>
          <div
            v-else
            :aria-label="$t(item.langTranslationKey)"
            role="button"
            class="language-switcher__selected-item"
          >
            <span>{{ $t(item.langTranslationKey) }}</span>
          </div>
        </template>
        <template #item="item">
          <div
            :aria-label="$t(item.langTranslationKey)"
            role="button"
            :data-value="item.langKey"
            class="language-switcher__item"
            :class="{
              'language-switcher__item-selected': language === item.langKey,
            }"
          >
            <span>{{ $t(item.langTranslationKey) }}</span>
            <img
              v-if="language === item.langKey"
              src="@shared/assets/img/checkbox-normal-midnight.svg"
              :alt="$t('generic_language')"
            />
          </div>
        </template>
      </DropdownList>
    </div>
  </div>
</template>

<script setup>
import DropdownList from '@shared/components/DropdownList.vue'
import { getSupportedLangCodes, getLangCodeByLanguage } from '@shared/utils'
import { useStore } from 'vuex'
import { computed } from 'vue'
import GlobeIcon from '@shared/assets/icon/GlobeIcon.vue'
import BaseButton from '@shared/components/BaseButton.vue'

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  short: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['change'])

const store = useStore()

const language = computed(() => store.getters.lang)

const _LanguageMap = {
  'en-US': 'generic_english',
  'de-DE': 'generic_german',
  'es-US': 'generic_american_spanish',
}

const dropdownItems = computed(() =>
  Object.entries(_LanguageMap)
    .map(([langKey, langTranslationKey]) => ({
      id: langKey,
      langKey,
      langTranslationKey,
      short: langKey.split('-')[0].toUpperCase(),
    }))
    .filter(({ langKey }) => {
      const languageCode = getLangCodeByLanguage(langKey)
      return getSupportedLangCodes().includes(languageCode)
    }),
)

const handleLanguageChange = (language) => {
  store.dispatch('setLang', language)
  emit('change', language)
}
</script>

<style lang="scss">
.language-switcher {
  display: flex;
  align-items: center;
  gap: 12px;

  &__header {
    @include kaia-typography-p2($font-weight-bold);
  }

  &__selected-item {
    color: $ocean;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-items: center;
    gap: $kds-space-1x;
    padding: $kds-space-1x;

    @include kaia-typography-p2($font-weight-medium);

    @include media-up(md) {
      padding: $kds-space-1x $kds-space-2x;
    }
  }

  &__items-layout {
    width: auto;
    max-height: 126px;
    padding: 12px 0;

    background-color: $subtle;
    border: 1.5px solid $background-interactive-subtle-active;
    border-radius: 4px;
    overflow-y: auto;
    color: $midnight;

    .language-switcher__item {
      display: flex;
      padding: 8px 16px;
      justify-content: start;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      @include kaia-typography-p2($font-weight-medium);

      &:hover {
        background-color: $background-interactive-subtle-hover;
      }
      &-selected,
      &-selected:hover {
        background-color: $background-interactive-subtle-active;
      }

      img {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
      }
    }
  }

  &--disabled {
    opacity: 0.7;
  }

  &--disabled &__selected-item {
    cursor: default;
  }
}
</style>
