import { arrayToObject } from './utils'

function normalizeNamespace(fn) {
  return (...params) => {
    let [namespace, map, getterType, mutationType] =
      typeof params[0] === 'string' ? [...params] : ['', ...params]

    if (namespace.length && namespace.charAt(namespace.length - 1) !== '/') {
      namespace += '/'
    }

    getterType = `${namespace}${getterType || 'getField'}`
    mutationType = `${namespace}${mutationType || 'updateField'}`

    return fn(namespace, map, getterType, mutationType)
  }
}

export function getField(state) {
  return (path) => path.split(/[.[\]]+/).reduce((prev, key) => prev[key], state)
}

export function updateField(state, { path, value }) {
  path.split(/[.[\]]+/).reduce((prev, key, index, array) => {
    if (array.length === index + 1) {
      prev[key] = value
    }

    return prev[key]
  }, state)
}

export const mapFields = normalizeNamespace(
  (namespace, fields, getterType, mutationType) => {
    const fieldsObject = Array.isArray(fields) ? arrayToObject(fields) : fields

    return Object.keys(fieldsObject).reduce((prev, key) => {
      const path = fieldsObject[key]

      prev[key] = {
        get() {
          // console.log(this.$store.getters[getterType](path));
          return this.$store.getters[getterType](path)
        },
        set(value) {
          // console.log(path);
          this.$store.commit(mutationType, { path, value })
        },
      }

      return prev
    }, {})
  },
)

/**
 * @deprecated
 */
export const createHelpers = ({ getterType, mutationType }) => ({
  [getterType]: getField,
  [mutationType]: updateField,
  mapFields: normalizeNamespace((namespace, fields) =>
    mapFields(namespace, fields, getterType, mutationType),
  ),
})
