<template>
  <div
    class="popup-wrapper"
    :style="`top: ${topPosition}px;`"
  >
    <div class="popup-body">
      <button
        type="button"
        class="btn-floating-cross"
        @click="closePopup"
      />
      <span
        class="popup-body-title"
        v-html="titleComputed"
      />
      <div
        class="popup-body-text"
        v-html="text"
      />
    </div>
    <div
      class="popup-mask"
      @click="closePopup"
    />
  </div>
</template>

<script>
import { useI18n } from 'petite-vue-i18n'

export default {
  name: 'QuizInfoPopup',
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    topPosition: {
      type: Number,
      default: 0,
    },
  },
  emits: ['closePopup'],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    titleComputed() {
      return this.title != null
        ? this.title
        : this.t('b2b_self_test_about_this_question')
    },
  },
  mounted() {
    setTimeout(() => {
      this.$el.classList.add('popup-wrapper--visible')
    }, 150)
  },
  methods: {
    closePopup() {
      this.$emit('closePopup')
    },
  },
}
</script>

<style scoped lang="scss">
$popupRightMargin: 20px;
$popupTopMargin: 10px;
$popupBodyPadding: 24px;

.popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  opacity: 0;

  z-index: 9900; /*Make sure it is below $modal index in Modal.vue which is 9999*/

  background-color: transparent;
  transition: opacity 0.3s linear;

  &--visible {
    opacity: 1;
  }

  .popup-body {
    width: 80%;
    height: auto;
    max-width: 400px;
    padding: $popupBodyPadding;

    float: right;
    margin: $popupTopMargin $popupRightMargin;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.1);
    background-color: $yellow;
    border-radius: 6px;

    @media (max-width: $breakpoint-mobile) {
      float: none;
      margin: $popupTopMargin auto;
    }

    .popup-body-title {
      height: auto;
      font-size: 17px;
      line-height: 23px;
      font-weight: bold;
    }

    .popup-body-text {
      height: auto;
      font-size: 17px;
      line-height: 23px;
    }

    .btn-floating-cross {
      float: right;
      margin-right: -$popupBodyPadding;
      margin-top: -$popupBodyPadding;
      background: url('../../assets/img/b2b/icon-cross.svg') no-repeat center;
      height: 50px;
      width: 50px;
      cursor: pointer;
      border: transparent;

      &--hidden {
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  .popup-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: -1;
  }
}
</style>
