<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.28858 1.23078L6.29049 1.22761C6.65306 0.629887 7.3014 0.264862 8.00049 0.264862C8.69958 0.264862 9.34793 0.629887 9.7124 1.23078L15.3645 10.6667C15.7198 11.2821 15.722 12.0398 15.3701 12.6572C15.0182 13.2746 14.3651 13.6588 13.6472 13.6667H2.3465C1.63591 13.6588 0.982831 13.2746 0.630924 12.6572C0.279017 12.0398 0.281139 11.2821 0.641913 10.6574L6.28858 1.23078ZM8.00049 11C8.36868 11 8.66716 10.7015 8.66716 10.3334C8.66716 9.96517 8.36868 9.66669 8.00049 9.66669C7.6323 9.66669 7.33382 9.96517 7.33382 10.3334C7.33382 10.7015 7.6323 11 8.00049 11ZM7.33382 5.00002V7.66669C7.33382 8.03488 7.6323 8.33336 8.00049 8.33336C8.36868 8.33336 8.66716 8.03488 8.66716 7.66669V5.00002C8.66716 4.63183 8.36868 4.33336 8.00049 4.33336C7.6323 4.33336 7.33382 4.63183 7.33382 5.00002Z"
      fill="#D82A54"
    />
  </svg>
</template>
