<template>
  <InputWrapper
    class="checkbox-field"
    :errors="errors"
    :shrink-label="false"
  >
    <input
      :id="dataQa"
      v-model="model"
      type="checkbox"
      :data-qa="dataQa"
      v-bind="$attrs"
      :class="{ 'checkbox--input': true, error: !!errors.length }"
      @change="
        (e) => {
          emit('input', e)
          // also validate the checkbox on change (aka show required error immediately on unchecked)
          emit('blur', e)
        }
      "
      @blur="(e) => emit('blur', e)"
      @focus="(e) => emit('focus', e)"
    />
    <label
      :for="dataQa"
      class="checkbox--label"
    >
      <slot>
        <span>{{ label }}</span>
      </slot>
    </label>
  </InputWrapper>
</template>

<script setup>
import InputWrapper from './InputWrapper.vue'

defineOptions({
  inheritAttrs: false,
})

defineProps({
  errors: {
    type: Array,
    default: () => [],
  },
  dataQa: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
})

const model = defineModel({ default: false, type: Boolean })

const emit = defineEmits(['update:modelValue', 'input', 'blur', 'focus'])

//--wrapper--box-shadow: 0;
</script>

<style lang="scss">
:root {
  --checkbox-input--box-shadow: inset 0px 0px 0px 3px #{$line-strong};
}

%box-shadow {
  -webkit-box-shadow: var(--checkbox-input--box-shadow);
  -moz-box-shadow: var(--checkbox-input--box-shadow);
  box-shadow: var(--checkbox-input--box-shadow);
}

.checkbox-field > .wrapper__container {
  --wrapper--background-color: 0;
  --wrapper--box-shadow: 0;
}

.wrapper {
  &__container {
    @include flex-container(row, flex-start, sm, flex-start);
    position: relative;

    &--field {
      &--input {
        @include flex-container(row, flex-start, sm);

        .checkbox {
          &--input {
            flex-shrink: 0;
            position: relative;
            cursor: pointer;
            height: 24px;
            width: 24px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &:after {
              @extend %box-shadow;
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              border-radius: 5px;
              margin-right: 8px;
            }

            &:checked {
              &:after {
                --checkbox-input--box-shadow: inset 0px 0px 0px 3px #{$midnight};
                background-color: $midnight;
                background-image: url(@shared/assets/img/checkbox.svg);
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 50%;
              }
            }

            &.error {
              --checkbox-input--box-shadow: inset 0px 0px 0px 3px #{$danger};
            }
          }

          &--label {
            @include kaia-typography-p1(normal);
            align-self: center;
          }

          &:focus {
            border-color: $ocean;
          }
        }
      }
    }
  }
}
</style>
