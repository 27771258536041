<template>
  <transition name="sticky">
    <div
      v-if="shouldDisplay"
      class="sticky-header"
    >
      <div class="sticky-header-inner">
        <div class="logo">
          <img
            src="@shared/assets/img/logo-mobile.svg"
            alt="logo"
          />
        </div>

        <div class="caption">
          {{ $t('kaia_for_free') }}
        </div>

        <button
          type="button"
          class="btn btn-secondary get-started"
          @click="ctaWasClicked"
        >
          {{ stickyHeaderCaption }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { debounce } from '@shared/utils'

export default {
  name: 'StickyHeader',
  props: {
    showStickyHeader: {
      type: Function,
      default: () => false,
    },
    stickyHeaderCaption: {
      type: String,
      default: '',
    },
    ctaWasClicked: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      shouldDisplay: false,
    }
  },
  mounted() {
    this.debounceHandleScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.debounceHandleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.debounceHandleScroll)
    this.debounceHandleScroll = null
  },
  methods: {
    handleScroll() {
      this.shouldDisplay = this.showStickyHeader() && this.stickyHeaderCaption
    },
  },
}
</script>

<style lang="scss">
@import './css/StickyHeader';
</style>
