<template>
  <div class="full-page-preloader">
    <Spinner></Spinner>
  </div>
</template>

<script>
import Spinner from '@shared/components/Spinner.vue'

export default {
  name: 'FullPagePreloader',
  components: {
    Spinner,
  },
}
</script>

<style lang="scss" scoped>
.full-page-preloader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10;
}
</style>
