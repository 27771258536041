<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 11C2.5 13.3333 6.8 18 12 18C17.2 18 21.5 13.3333 23 11"
      stroke="#515559"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M1 11C2.5 8.66667 6.8 4 12 4C17.2 4 21.5 8.66667 23 11"
      stroke="#515559"
      stroke-width="2"
      stroke-linecap="round"
    />
    <circle
      cx="12"
      cy="11"
      r="3"
      fill="#515559"
      stroke="#515559"
      stroke-width="2"
    />
  </svg>
</template>
