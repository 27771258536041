import { PublicApi, PrivateApi } from '@shared/api-client'

/** @typedef {import("axios").AxiosResponse} AxiosResponse */
/** @typedef {import("@shared/data/models/user").default} User */

/**
 * @param { Object } objectWithNullishEntries
 * @returns { {key: string, value: any}[] } containing all entries from the input data that are not nullish
 */
const toListOfNonNullishKeyValueObjects = (objectWithNullishEntries) =>
  Object.entries(objectWithNullishEntries)
    .filter(([, value]) => value !== null && value !== undefined)
    .map(([key, value]) => ({ key, value }))

/**
 * @param { Object } objectWithNullishEntries
 * @returns { Object } containing all entries from the input data that are not nullish
 */
const toObjectOfNonNullishEntries = (objectWithNullishEntries) =>
  Object.fromEntries(
    Object.entries(objectWithNullishEntries).filter(
      ([, value]) => value !== null && value !== undefined,
    ),
  )

/** @returns { Promise<AxiosResponse<{user: User}>> } */
export const login = (email, password, stayLoggedIn) => {
  const payload = { email, password }
  if (typeof stayLoggedIn === 'boolean') {
    payload.stay_logged_in = stayLoggedIn
  }

  return PublicApi.post('v2/auth/sign_in/', payload)
}

/** @returns { Promise<AxiosResponse<{user: User}>> } */
export const signUp = ({
  email,
  password,
  firstName: first_name,
  lastName: last_name,
  insuranceType,
  phoneNumber: phone_number,
  dateOfBirth: date_of_birth,
  language,
  country,
  timezone,
  acceptedDataProtection: accepted_data_protection,
  acceptedTracking: accepted_tracking,
  stayLoggedIn: stay_logged_in,
  corporateId: corporate_id,
  corporateEligibilityData,
  fromApp: from_app, // from session storage
  verificationEmail,
  eligibilitySessionHash: eligibility_session_hash,
  lookupKey: solera_lookup_key,
  landingPageExperimentVariant: landing_page_experiment_variant,
  signupContext,
}) =>
  PublicApi.post('v2/auth/sign_up/', {
    email,
    password,
    first_name,
    corporate_id,
    language,
    country,
    timezone,
    accepted_data_protection,
    accepted_tracking,
    stay_logged_in,
    ...(last_name && { last_name }),
    ...(phone_number && { phone_number }),
    ...(eligibility_session_hash && { eligibility_session_hash }),
    ...(solera_lookup_key && { solera_lookup_key }),
    ...(insuranceType && {
      insurance_type:
        insuranceType === 'private' ? 'private_insurance' : 'public_insurance',
    }),
    signup_context: {
      web_entry_point_url: signupContext.webEntryPointUrl,
      web_entry_point_referrer_url: signupContext.webEntryPointReferrerUrl,
      how_did_you_hear_about_us: signupContext.howDidYouHearAboutUs,
    },
    feedback: [
      ...toListOfNonNullishKeyValueObjects({
        date_of_birth,
        from_app,
        email: verificationEmail,
        landing_page_experiment_variant,
        ...corporateEligibilityData,
      }),
    ],
  })

export const resetPassword = ({ email }) =>
  PublicApi.post('v2/auth/password/reset_init', { email })

/** @returns { Promise<AxiosResponse<{user: User}>> } */
export const loginWithOneTimeAuthToken = (token) =>
  PublicApi.post('/1fa/auth/sign_in/token', { token })

export const getOneTimeAuthToken = () => PrivateApi.get('v2/auth/token')

export const subscribeToDvg = ({ code, corporateId: corporate_id }) =>
  PrivateApi.post('v2/subscriptions/subscribe_to_dvg', { code, corporate_id })

export const sendVerificationData = (data) =>
  PrivateApi.post('v2/user/add_feedback/', {
    feedback: Object.keys(data).map((k) => ({
      key: k === 'participationId' ? 'participation_id' : k,
      value: data[k],
    })),
  })

// currently only used for generali in the EU, to validate whether this would also work for eligibility list in the US
export const triggerCorporateSubscriptionCreationOrProlongation = ({
  corporateKey: corporate_key,
  eligibilityParams: eligibility_check = {},
  eligibilitySessionHash: eligibility_session_hash,
}) => {
  return PrivateApi.post('v2/user/partner_subscriptions/', {
    corporate_key,
    eligibility_check,
    eligibility_session_hash,
  })
}

export const uploadPrescriptionFile = (formData, params) => {
  params = Object(params) === params ? params : {}

  return PrivateApi.post('v2/user/prescriptions', formData, params)
}

export const getPopularCorporates = () => PublicApi.get('popular_corporates')

export const sendHcpInformation = ({ name, city, zip }) =>
  PrivateApi.post('v2/user/hcp_information', {
    name,
    city,
    ...(typeof zip === 'string' && zip.length > 0 ? { zip } : {}),
  })

export const sendSubscriptionActivationEntry = ({ type, attributes }) =>
  PrivateApi.post('v2/subscription_activation_entries', {
    data: {
      type,
      attributes,
    },
  })

export const sendUserFeedback = ({
  firstName: first_name,
  lastName: last_name,
  phoneNumber: phone_number,
  dateOfBirth: date_of_birth,
  language,
  country,
  timezone,
  acceptedDataProtection: accepted_data_protection,
  corporateId: corporate_id,
  stayLoggedIn: stay_logged_in,
}) =>
  PrivateApi.post('v2/user/add_feedback/', {
    feedback: toListOfNonNullishKeyValueObjects({
      first_name,
      last_name,
      phone_number,
      date_of_birth,
      language,
      country,
      timezone,
      accepted_data_protection,
      corporate_id,
      stay_logged_in,
    }),
  })

/** @returns { Promise<AxiosResponse<{user: User}>> } */
export const subscribeB2BUserWithVoucher = ({ code }) =>
  PrivateApi.post('v2/payment/subscribe_with_corporate_voucher', { code })

export const checkVoucherB2b = (code) =>
  PublicApi.post('v2/check_corporate_voucher/', { code })

export const checkEmailB2b = (email, corporate) =>
  PublicApi.get(
    `v2/check_autosubscribe_address?email=${encodeURIComponent(
      email,
    )}&corporate_key=${encodeURIComponent(corporate)}`,
  )

/** @returns { Promise<AxiosResponse<Corporate>> } */
export const getCorporate = (name, lang, id) =>
  id === null || id === undefined
    ? PublicApi.get(`v2/content/get_corporate?key=${name}&language=${lang}`)
    : PublicApi.get(`v2/content/get_corporate?id=${id}&language=${lang}`)

/** @returns { Promise<AxiosResponse<{user: User}>> } */
export const getUser = () => PrivateApi.get('1fa/user')

/** @returns { Promise<AxiosResponse<{user: User}>> } */
export const setUserProfile = ({
  firstName: first_name,
  acceptedDataProtection: accepted_data_protection,
  acceptedTracking: accepted_tracking,
  phoneNumber: phone_number,
  corporateId: corporate_id,
}) =>
  PrivateApi.patch(
    '1fa/profile',
    toObjectOfNonNullishEntries({
      first_name,
      accepted_data_protection,
      accepted_tracking,
      phone_number,
      corporate_id,
    }),
  )

export const setUserEmail = (email) =>
  PrivateApi.post('v2/user/set_email', { email })

export const getCorporateEligibilityConfig = (corporateKey, lang) =>
  PublicApi.get(
    `v2/corporates/${corporateKey}/eligibility_config?language=${lang}`,
  )

export const checkCorporateEligibility = (corporateEligibilityData) =>
  PublicApi.get('v2/check_corporate_eligibility', {
    params: corporateEligibilityData,
  })

export const getCorporateEligibilityGroupConfig = (groupId, lang) =>
  PublicApi.get(
    `v2/corporate_eligibility_groups/${groupId}/eligibility_config?language=${lang}`,
  )

export const checkCorporateGroupEligibility = (corporateGroupEligibilityData) =>
  PublicApi.get('v2/check_corporate_group_eligibility', {
    params: corporateGroupEligibilityData,
  })

export const checkEligibilityWithEligibleToken = (
  eligibility_session_hash,
  eligible_token,
) =>
  PublicApi.post('v2/check_eligibility/with_token', {
    eligibility_session_hash,
    eligible_token,
  })

/**
 * @param { string } locale of the faqs, 'en-US' or 'es-US'
 * @typedef { id: string, title: string, order: number, text: string} Faq
 * @typedef { count: number, faqs: Faq[] } FaqList
 * @returns { Promise<AxiosResponse<{ count: number, faqs: Faq[] }>> }: count of faqs and list of faqs
 */
export const getHelpscoutFaqs = async (locale) =>
  await PublicApi.get('v2/support/faqs', {
    params: { locale },
  })

export const submitSupportTicket = async (data) =>
  await PublicApi.post('v2/support/tickets', data)

export const getCheckups = () => PrivateApi.get('1fa/user_checkups')

export const submitSelfTest = (data) =>
  PrivateApi.post('v2/user/complete_self_test', data)

export const submitSelfTestReevaluations = (data) =>
  PrivateApi.post('v2/user/assessment_questionnaire_results', {
    assessment_questionnaire_results: {
      self_test_reevaluation: data,
    },
  })

export const submitCAT = (data) =>
  PrivateApi.post('v2/user/assessment_questionnaire_results', {
    assessment_questionnaire_results: {
      cat: data,
    },
  })

export const searchCorporate = (search, country, abortSignal) =>
  PublicApi.get('v2/search_corporate', {
    params: { search, ...(country ? { country } : {}) },
    signal: abortSignal,
  })

/** @returns { Promise<AxiosResponse<{user: User}>> } */
export const subscribeWithInsuranceToken = (corporateKey, token, type) =>
  PrivateApi.post('v2/payment/subscribe_with_insurance_token', {
    corporate_name: corporateKey,
    token,
    type,
  })

/** @returns { Promise<AxiosResponse<{user: User}>> } */
export const subscribeWithEligibleToken = (
  eligibility_session_hash,
  eligible_token,
) =>
  PrivateApi.post('v2/payment/subscribe_with_eligible_token', {
    eligibility_session_hash,
    eligible_token,
  })

export const sendBrazeEvent = ({ name, properties }) => {
  if (name === null || name === undefined) {
    return Promise.reject({
      id: 1,
      message: `sendBrazeEvent: expected ${name} to be a string;`,
    })
  }

  const payload = { name }

  if (properties !== null && properties !== undefined) {
    payload.properties = properties
  }

  return PrivateApi.post('v2/user/send_crm_event', payload)
}
