<template>
  <ResponsiveLayout>
    <div
      v-if="connecting"
      class="saml-sso-spinner"
    >
      <Spinner v-if="personifyHealthMigration.isMigrated(corporate.key)">
        {{ $t('b2b__SSO__redirecting_to_personify_health') }}
      </Spinner>
      <Spinner v-else>{{ $t('b2b__SSO__redirecting_to_virginpulse') }}</Spinner>
    </div>
    <div
      v-else
      class="saml-sso kds-main-wrapper"
    >
      <div class="kds-content-block">
        <p class="kds-typography-display-small">
          {{ $t('saml_sso__login_employer_select__title') }}
        </p>
        <p class="kds-typography-paragraph-large">
          {{ $t('saml_sso__login_employer_select__description') }}
        </p>
      </div>

      <div>
        <TextField
          v-model="searchTerm"
          :label="$t('saml_sso__login_employer_select__filter_label')"
        />
        <div
          v-for="item in filteredCorporateMap"
          :key="item.key"
          class="saml-sso__corporate_list_item"
          tabindex="0"
          :data-qa="`corporate-${item.key}`"
          @click="connect(item.key)"
        >
          <p class="kds-typography-interactive-medium">{{ item.title }}</p>
          <ChevronRight />
        </div>
      </div>
    </div>
  </ResponsiveLayout>
</template>

<script setup>
import { useStore } from 'vuex'
import Spinner from '@shared/components/Spinner.vue'
import { computed, ref } from 'vue'
import ChevronRight from '@shared/assets/icon/ChevronRight.vue'
import TextField from '@shared/components/form/TextField.vue'
import personifyHealthMigration from '@msk-us/config/personify-health-migration.js'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'

const store = useStore()

const corporate = computed(() => store.getters['b2b/corporate'])
const connecting = ref(false)
const searchTerm = ref('')

const filteredCorporateMap = computed(() =>
  personifyHealthMigration.corporateMap.filter(
    (item) =>
      item.title &&
      item.title.toLowerCase().includes(searchTerm.value.toLowerCase()),
  ),
)

const redirectTo = (path, query = {}) => {
  const url = new URL(path, import.meta.env.VITE_API_BASE_URL)
  Object.entries(query).forEach(([key, value]) =>
    url.searchParams.append(key, value.toString()),
  )
  window.location.href = url.toString()
}

const connectWithPersonifyHealth = () => {
  redirectTo('saml-sp/personify-health/init')
}

const connectWithPersonifyHealthMock = () => {
  redirectTo(`saml-sp/personify-health/mocked`, {
    FirstName: 'Test',
    LastName: 'Automation',
    Email: 'sebastian.stein+ph1@kaiahealth.com',
    EmployeeID: 1,
    MemberID: 1,
    SponsorID: 28856,
    // this is internally used as uid, so using the email here to simplify user identification
    NameID: 'sebastian.stein+ph1@kaiahealth.com',
    redirect_host: window.location.hostname,
    redirect_port: window.location.port,
    redirect_scheme: window.location.protocol.split(':')[0],
  })
}

const connectWithVirginpulse = () => {
  redirectTo('auth/saml', { resource_class: 'User' })
}

const connectWithMockedIDP = () => {
  if (window.location.hostname === 'localhost') {
    redirectTo('v2/dev_auth/saml', { resource_class: 'User' })
  } else {
    redirectTo('v2/fake_auth/saml', {
      resource_class: 'User',
      redirect_host: window.location.hostname,
    })
  }
}

const connect = (corporateKey) => {
  connecting.value = true
  if (personifyHealthMigration.isMigrated(corporateKey)) {
    // personifyhealth
    if (corporateKey === 'mocked-idp') {
      connectWithPersonifyHealthMock()
    } else {
      connectWithPersonifyHealth()
    }
  } else {
    // virgin pulse
    if (corporateKey === 'mocked-idp') {
      connectWithMockedIDP()
    } else {
      connectWithVirginpulse()
    }
  }
}

if (!store.getters['b2b/verificationParams']?.login) {
  // if the corporate is known (eg signup flow) directly connect
  connect(corporate.value.key)
} else if (personifyHealthMigration.noneAreMigrated()) {
  // if no corporates are migrated so far directly connect with virginpulse
  connect('virginpulse')
} else if (personifyHealthMigration.allAreMigrated()) {
  // if no corporates are migrated so far directly connect with personifyhealth
  connect('personifyhealth')
} else {
  // ask user for employer
}
</script>

<style lang="scss">
.saml-sso-spinner {
  height: 100vh;

  .spinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.saml-sso {
  &__corporate_list_item {
    cursor: pointer;
    box-shadow: inset 0 -1px 0 0 $kds-color-line-subtle;
    padding: $kds-space-2x;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-of-type {
      box-shadow: unset;
    }

    &:hover {
      background-color: $kds-color-background-subtle;
    }

    &:focus {
      box-shadow: inset 0 0 0 3px $kds-color-line-focus;
      outline: none;
    }
  }
}
</style>
