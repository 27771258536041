<template>
  <div
    :data-qa="dataQA"
    :class="getCSSClasses"
  >
    <div :class="getControlCSSClasses">
      <slot />
    </div>
    <div
      v-if="errorMessage"
      class="form-field__error js-form-field__error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    hasFocus: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    cssClasses: {
      type: Array,
      default: () => [],
    },
    controlCSSClasses: {
      type: Array,
      default: () => [],
    },
    dataQA: {
      type: String,
      default: '',
    },
  },
  computed: {
    getCSSClasses() {
      return [
        'form-field',
        this.hasFocus ? 'form-field__focused' : '',
        this.isInvalid ? 'form-field__invalid js-form-field__invalid' : '',
        ...this.cssClasses,
      ]
    },
    getControlCSSClasses() {
      return [
        'form-field__control',
        this.isActive ? 'form-field--is-active' : '',
        this.isFilled ? 'form-field--is-filled' : '',
        ...this.controlCSSClasses,
      ]
    },
  },
}
</script>

<style lang="scss">
@import './css/FormField.scss';
</style>
