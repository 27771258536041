<template>
  <ResponsiveLayout>
    <form class="kds-main-wrapper">
      <h1>Init mocked personify health handshake</h1>
      <TextField
        ref="firstNameRef"
        v-bind="fieldAttrs.firstName"
        autocomplete="given-name"
        label="First Name"
        data-qa="first-name"
      />
      <TextField
        v-bind="fieldAttrs.lastName"
        autocomplete="family-name"
        label="Last Name"
        data-qa="last-name"
      />
      <TextField
        v-bind="fieldAttrs.email"
        type="email"
        autocomplete="email"
        label="Email"
        data-qa="email"
      />
      <TextField
        v-bind="fieldAttrs.employeeID"
        label="Employee ID"
        hint="This is the users identifier within the group"
        data-qa="employee-id"
      />
      <TextField
        v-bind="fieldAttrs.memberID"
        label="Member ID"
        hint="This is the users identifier within the group"
        data-qa="member-id"
      />
      <TextField
        v-bind="fieldAttrs.sponsorID"
        label="Sponsor ID"
        hint="This maps to our corporates"
        data-qa="sponsor-id"
      />
      <BaseButton
        v-bind="submitAttrs"
        text="Go to Kaia"
        data-qa="submit"
      />
    </form>
  </ResponsiveLayout>
</template>

<script setup>
import BaseButton from '@shared/components/BaseButton.vue'
import TextField from '@shared/components/form/TextField.vue'
import useForm from '@shared/composables/useForm'
import { ref } from 'vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
// TODO dropdown for env selection (localhost + stage api, stage, prod)

const handleSubmitSuccess = () => {
  console.log(form.value)
  const url = new URL(
    'saml-sp/personify-health/mocked',
    import.meta.env.VITE_API_BASE_URL,
  )
  Object.entries({
    FirstName: form.value.firstName,
    LastName: form.value.lastName,
    Email: form.value.email,
    EmployeeID: form.value.employeeID,
    MemberID: form.value.memberID,
    SponsorID: form.value.sponsorID,
    // this is internally used as uid, so using the email here to simplify user identification
    NameID: form.value.email,
    redirect_host: window.location.hostname,
    redirect_port: window.location.port,
    redirect_scheme: window.location.protocol.split(':')[0],
  }).forEach(([key, value]) => url.searchParams.append(key, value))

  window.location = url.toString()
}

const form = ref({
  firstName: '',
  lastName: '',
  email: '',
  employeeID: '',
  memberID: '',
  sponsorID: '',
})

const { fieldAttrs, submitAttrs } = useForm(
  form,
  ref({
    firstName: { validations: ['required'] },
    lastName: { validations: ['required'] },
    email: { validations: ['required', 'email'] },
    employeeID: { validations: ['required'] },
    memberID: { validations: ['required'] },
    sponsorID: { validations: ['required'] },
  }),
  {
    onSubmit: () => {},
    onSuccess: () => handleSubmitSuccess(),
    onError: (error) => console.log('error: ' + error),
  },
)
</script>

<style lang="scss"></style>
