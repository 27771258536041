<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 7.56227V8.46886H7.35852V7.65293C7.35852 5.4771 9.05838 4.45719 11.1209 4.45719C13.1607 4.45719 14.7699 5.4771 14.7699 7.33562C14.7699 8.99015 13.886 9.71543 12.2541 10.3954L11.6195 10.6674C10.2143 11.2566 9.91964 12.0272 9.91964 13.3871V14.7017H11.7555V13.4325C11.7555 12.6845 11.9595 12.3446 12.5488 12.0726L13.206 11.8006C15.0419 11.03 16.6284 9.82875 16.6284 7.38095V7.2223C16.6284 4.63851 14.3393 2.75732 11.1209 2.75732C7.85714 2.75732 5.5 4.50252 5.5 7.56227ZM9.26236 17.6934C9.26236 18.6 9.94231 19.2573 10.8262 19.2573C11.7328 19.2573 12.3901 18.6 12.3901 17.6934C12.3901 16.7869 11.7328 16.1296 10.8262 16.1296C9.94231 16.1296 9.26236 16.7869 9.26236 17.6934Z"
      fill="#3676C9"
    />
  </svg>
</template>
