import AbstractModel from './utils/abstractModel'
import types from './utils/types'

class Corporate extends AbstractModel {
  // This model is intended to be used whenever we're dealing with a corporate object rendered by the API

  /**
   * @param {string}
   * @readonly
   */
  additionalLegalDocFile

  /**
   * @type {array}
   * @readonly
   */
  alternateTitles

  /**
   * @type {array.<string>}
   * @readonly
   */
  corporateTypes

  /**
   * not coming from API. Can be used in order to customize the title of the corporate in some flow (e.g. dropdown...)
   * @param {string | undefined}
   */
  customTitle

  /**
   * @param {boolean}
   * @readonly
   */
  disclaimerEnabled

  /**
   * @param {string}
   * @readonly
   */
  disclaimerText

  /**
   * @type {number}
   * @readonly
   */
  id

  /**
   * @type {boolean}
   * @readonly
   */
  isCustomer

  /**
   * @type {boolean}
   * @readonly
   */
  isPrivateInsurance

  /**
   * @type {boolean}
   * @readonly
   */
  isPublicInsurance

  /**
   * @param {boolean}
   * @readonly
   */
  kaiaTermsEnabled

  /**
   * @type {string}
   * @readonly
   */
  key

  /**
   * @param {string}
   * @readonly
   */
  logoFile

  /**
   * @param {string}
   * @readonly
   */
  preferredSignupMethod

  /**
   * @param {string}
   * @readonly
   */
  prescriptionUploadMode

  /**
   * @type {object, null}
   * @readonly
   */
  primaryAddress

  /**
   * @param {boolean}
   * @readonly
   */
  showExtendedOnboarding

  /**
   * @param {string}
   * @readonly
   */
  signupInfoPageUrl

  /**
   * @type {string}
   * @readonly
   */
  title

  /**
   * @param {Object.<string, string>}
   * @readonly
   */
  translations

  /**
   * @param {string}
   * @readonly
   */
  virginPulseSponsorId

  constructor(json) {
    super(json)
    this.id = json.id
    this.title = json.title
    this.customTitle = json.custom_title || json.title
    this.isCustomer = json.is_customer || false
    this.key = json.key
    this.isPublicInsurance = json.is_public_insurance || false
    this.isPrivateInsurance = json.is_private_insurance || false
    this.corporateTypes = json.corporate_types || []
    this.alternateTitles = json.alternate_titles || []
    this.preferredSignupMethod = json.preferred_signup_method
    this.additionalLegalDocFile =
      json.additional_legal_doc_file !== null &&
      json.additional_legal_doc_file?.length > 0
        ? json.additional_legal_doc_file
        : null
    this.disclaimerText = json.disclaimer_text
    this.disclaimerEnabled = json.disclaimer_enabled
    this.logoFile = json.logo_file
    this.showExtendedOnboarding = json.show_extended_onboarding
    this.primaryAddress = json.primary_address || {}
    this.translations = json.translations
    this.signupInfoPageUrl = json.signup_info_page_url
    this.prescriptionUploadMode = json.prescription_upload_mode
    this.kaiaTermsEnabled = json.kaia_terms_enabled
    this.virginPulseSponsorId = json.virgin_pulse_sponsor_id

    this.validate()
  }

  validate() {
    this.assertType('id', [types.NUMBER])
    this.assertType('title', [types.STRING])
    this.assertType('customTitle', [types.STRING, types.NULL])
    this.assertType('isCustomer', [types.BOOLEAN])
    this.assertType('key', [types.STRING])
    this.assertType('isPublicInsurance', [types.BOOLEAN])
    this.assertType('isPrivateInsurance', [types.BOOLEAN])
    this.assertType('corporateTypes', [types.ARRAY])
    this.assertType('alternateTitles', [types.ARRAY])
    this.assertType('preferredSignupMethod', [types.STRING, types.NULL])
    this.assertType('additionalLegalDocFile', [types.STRING, types.NULL])
    this.assertType('disclaimerText', [types.STRING, types.NULL])
    this.assertType('disclaimerEnabled', [types.BOOLEAN, types.NULL])
    this.assertType('logoFile', [types.STRING, types.NULL])
    this.assertType('showExtendedOnboarding', [types.BOOLEAN, types.NULL])
    this.assertType('translations', [types.JSON, types.NULL])
    this.assertType('signupInfoPageUrl', [types.STRING, types.NULL])
    this.assertType('prescriptionUploadMode', [types.STRING, types.NULL])
    this.assertType('primaryAddress', [types.JSON])
    this.assertType('kaiaTermsEnabled', [types.BOOLEAN, types.NULL])
    this.assertType('virginPulseSponsorId', [types.STRING, types.NULL])
  }

  /**
   * Check if the corporate is a demo corporate
   * @returns {boolean}
   * @readonly
   */
  get isDemo() {
    return !this.isPublicInsurance && !this.isPrivateInsurance
  }

  /**
   * Type of insurance of the corporate
   * @returns {'private' | 'public' | 'not_set'}
   * @readonly
   */
  get insuranceType() {
    if (this.isPrivateInsurance && !this.isPublicInsurance) {
      return 'private'
    } else if (!this.isPrivateInsurance && this.isPublicInsurance) {
      return 'public'
    } else {
      return 'not_set'
    }
  }

  /**
   * check if additional legal doc file should be rendered
   * @returns {boolean}
   * @readonly
   */
  get hasAdditionalLegalDoc() {
    return this.additionalLegalDocFile !== null
  }

  /**
   * Check if the corporate has a disclaimer
   * @returns {boolean}
   * @readonly
   */
  get hasDisclaimer() {
    return (
      this.kaiaTermsEnabled || (this.disclaimerEnabled && !!this.disclaimerText)
    )
  }

  /**
   * Check if the corporate has a disclaimer
   * @returns {boolean}
   * @readonly
   */
  get hasDisclaimerText() {
    return this.disclaimerEnabled && !!this.disclaimerText
  }
}

export default Corporate
