<template>
  <!-- eslint-disable vue/no-v-html -->
  <slot v-bind="{ viewDocument }">
    <span
      @click="viewDocument"
      v-html="link"
    />
  </slot>
  <BaseModal
    ref="modal"
    :width="1000"
    can-click-outside
  >
    <slot name="iframe">
      <iframe :src="doc"></iframe>
    </slot>
  </BaseModal>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref } from 'vue'

import useVueSlots from '@shared/composables/useVueSlots.js'

import BaseModal from '@shared/components/BaseModal.vue'

const { hasSlot } = useVueSlots(['default', 'iframe'])

/**
 * @link [HTML] Should come from translation file and specify an a tag which references static documents.
 * For example: <a class="a-link" href="#" data-link="/static/us/terms/index.html">Terms and Conditions</a>
 * DataLink should be the path to the static document
 */
defineProps({
  link: {
    type: String,
    default: '',
  },
})

const modal = ref(null)
const doc = ref(null)

const viewDocument = (e) => {
  const { link } = e.target.dataset

  if (link) {
    e.preventDefault()
    doc.value = link
    modal.value.open()
  } else if (hasSlot.value['iframe']) {
    e.preventDefault()
    modal.value.open()
  }
}
</script>

<style lang="scss" scoped>
iframe {
  border: none;
  width: 100%;
  height: 80vh;
}
</style>
