import { validateDate } from '@shared/utils'
import {
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js'

/**
 * @param {string | undefined} String passed for validation
 * @returns {undefined | string} If error, returns error string otherwise undefined
 */
export const required = (value) => {
  if (!value) return 'generic__validation_error__required'
  return value?.trim() !== ''
    ? undefined
    : 'generic__validation_error__required'
}

/**
 * @param {any} value
 * @returns {string[] | []} Errors array
 */
export const password = (value) => {
  const errors = []

  if (value.length < 8) {
    errors.push('generic__password_field__hint__min_length')
  }

  if (!/[A-Z]/.test(value)) {
    errors.push('generic__password_field__hint__upper_case')
  }

  if (!/[a-z]/.test(value)) {
    errors.push('generic__password_field__hint__lower_case')
  }

  if (!/[\d\s!@#$%^&*()_+{}[\]:;<>,.?/\\~`|=-]+/.test(value)) {
    errors.push('generic__password_field__hint__number_or_special')
  }

  return errors
}

/**
 * @param {any} value
 * @returns {undefined|string} Returns string if error, otherwise undefined
 */
export const email = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(value) ? undefined : 'generic_email_has_invalid_format'
}

/**
 * @param {any} value
 * @param {blurredKeys: [], force: boolean} configuration
 * @returns {string[][]} Errors object converted to array
 */
export const dob = (value, { blurredKeys = [], force }) => {
  const [year = '', month = '', day = ''] = value.split('-')

  const results = []

  if (!day && (blurredKeys?.includes('day') || force)) {
    results.push(['day', 'date-of-birth__error__day--required'])
  }

  if (day > 31 && (blurredKeys?.includes('day') || force)) {
    results.push(['day', 'date-of-birth__error__day--too-large'])
  }

  if (!month && (blurredKeys.includes('month') || force)) {
    results.push(['month', 'date-of-birth__error__month--required'])
  }

  if (month > 12 && (blurredKeys.includes('month') || force)) {
    results.push(['month', 'date-of-birth__error__month--too-large'])
  }

  if (!year && (blurredKeys.includes('year') || force)) {
    results.push(['year', 'date-of-birth__error__year--required'])
  }

  const currentYear = new Date().getFullYear()
  if (year > currentYear && (blurredKeys.includes('year') || force)) {
    results.push(['year', 'date-of-birth__error__year--too-large'])
  }

  if (
    year.length &&
    year.length < 4 &&
    (blurredKeys.includes('year') || force)
  ) {
    results.push(['year', 'date-of-birth__error__year--incomplete'])
  }

  const date = new Date(year, month - 1, day)
  const allKeysBlurred =
    blurredKeys.includes('day') &&
    blurredKeys.includes('month') &&
    blurredKeys.includes('year')

  if (isNaN(date.getTime()) && (allKeysBlurred || force)) {
    results.push(['date', 'date-of-birth__error__invalid-date'])
  }

  const minAgeDate = new Date()
  minAgeDate.setFullYear(minAgeDate.getFullYear() - 16)

  if (date > minAgeDate && (allKeysBlurred || force)) {
    results.push(['date', 'date-of-birth__error__too-young'])
  }

  if (!validateDate(year, month - 1, day) && (allKeysBlurred || force)) {
    results.push(['date', 'date-of-birth__error__invalid-date'])
  }

  return results
}

export const phone = (value, { countryCode }) => {
  if (!value) return null

  if (validatePhoneNumberLength(value, countryCode)) {
    return 'phonenumber__error__is_invalid'
  }

  if (!isValidPhoneNumber(value, countryCode)) {
    return 'phonenumber__error__is_invalid'
  }
}

export const accepted = (value) => {
  if (!value) return 'accepted__error__checked'
}
