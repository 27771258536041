<template>
  <div :class="getCustomRadioCSSClasses">
    <input
      :id="name"
      :checked="checked"
      :value="value"
      :data-qa="dataQA"
      :data-qa-value="dataQAValue"
      type="radio"
      class="custom-radio__input"
      @click="handleClickEvent"
      @change="handleChangeEvent"
      @focus="handleFocusEvent"
      @blur="handleBlurEvent"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomRadio',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    dataQA: {
      type: String,
      default: '',
    },
    dataQAValue: {
      type: String,
      default: '',
    },
  },
  emits: ['change', 'focus', 'blur'],
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    getCustomRadioCSSClasses() {
      const classes = ['custom-radio']

      if (this.checked) {
        classes.push('custom-radio--checked')
      } else if (this.focused) {
        classes.push('custom-radio--focused')
      } else {
        classes.push('custom-radio--default')
      }

      return classes
    },
  },
  methods: {
    handleChangeEvent(event) {
      const { checked, value } = event.target
      this.$emit('change', {
        checked,
        value,
      })
    },
    handleFocusEvent() {
      this.focused = true
      this.$emit('focus')
    },
    handleBlurEvent() {
      this.focused = false
      this.$emit('blur')
    },
    handleClickEvent() {
      this.$el.querySelector('.custom-radio__input').checked = true
    },
  },
}
</script>

<style lang="scss">
@import '@shared/components/css/QuizRadio.scss';
@import '@shared/components/css/CustomRadio.scss';
</style>
