<template>
  <EnvBadge />
  <PageLoader />
  <Component
    :is="layout"
    v-if="layout && layout !== 'B2BDownloadLayout'"
    :set-page-title="setPageTitle"
  />
  <router-view
    v-if="!layout || layout === 'B2BDownloadLayout'"
    class="app-body"
  />
</template>

<script>
import EnvBadge from '@shared/components/EnvBadge.vue'
import PageLoader from '@shared/components/Pageloader.vue'
import FullPagePreloader from '@shared/components/FullPagePreloader.vue'
import Layout from '@msk-us/containers/Layout.vue'
import B2BLayout from '@msk-us/containers/B2BLayout.vue'
import B2BLandingLayout from '@msk-us/containers/B2BLandingLayout.vue'
import B2BLandingTestLayout from '@msk-us/containers/B2BLandingPageABTest.vue'
import B2BSimpleLandingLayout from '@msk-us/containers/B2BSimpleLandingLayout.vue'
import { changeLocale } from '@shared/localization'
import { getLangCodeByLanguage } from '@shared/utils'
import Tracker from '@shared/Tracker'
import { useI18n } from 'petite-vue-i18n'

export default {
  name: 'App',
  components: {
    EnvBadge,
    FullPagePreloader,
    PageLoader,
    Layout,
    B2BLayout,
    B2BLandingLayout,
    B2BSimpleLandingLayout,
    B2BLandingTestLayout,
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  computed: {
    layout() {
      return this.$route.meta.layout
    },
  },
  created() {
    // update the i18n language whenever the language in the store changes
    this.$store.watch(
      (state, getters) => getters.lang,
      (lang) => {
        changeLocale(getLangCodeByLanguage(lang), document.documentElement)
        // reload translations for the corporate
        if (this.$store.getters['b2b/corporate']) {
          this.$store.dispatch('b2b/fetchCorporate', {
            name: this.$store.getters['b2b/corporate'].key,
          })
        }
      },
      { immediate: true },
    )
    this.setPageTitle(this.t('b2b__landing_page__generic__page_title'))

    this.$store.watch(
      (state, getters) => getters.user,
      (user) => {
        Tracker.setUser(user)
      },
      { immediate: true },
    )
  },
  methods: {
    setPageTitle(newTitle) {
      if (newTitle && typeof newTitle === 'string') {
        document.querySelector('title').textContent = newTitle
      }
    },
  },
}
</script>

<style lang="scss">
/*@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap');*/
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('@shared/assets/fonts/SpaceGrotesk-Light.eot');
  src:
    local('☺'),
    url('@shared/assets/fonts/SpaceGrotesk-Light.woff') format('woff'),
    url('@shared/assets/fonts/SpaceGrotesk-Light.ttf') format('truetype'),
    url('@shared/assets/fonts/SpaceGrotesk-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('@shared/assets/fonts/SpaceGrotesk-Regular.eot');
  src:
    local('☺'),
    url('@shared/assets/fonts/SpaceGrotesk-Regular.woff') format('woff'),
    url('@shared/assets/fonts/SpaceGrotesk-Regular.ttf') format('truetype'),
    url('@shared/assets/fonts/SpaceGrotesk-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('@shared/assets/fonts/SpaceGrotesk-Medium.eot');
  src:
    local('☺'),
    url('@shared/assets/fonts/SpaceGrotesk-Medium.woff') format('woff'),
    url('@shared/assets/fonts/SpaceGrotesk-Medium.ttf') format('truetype'),
    url('@shared/assets/fonts/SpaceGrotesk-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('@shared/assets/fonts/SpaceGrotesk-SemiBold.eot');
  src:
    local('☺'),
    url('@shared/assets/fonts/SpaceGrotesk-SemiBold.woff') format('woff'),
    url('@shared/assets/fonts/SpaceGrotesk-SemiBold.ttf') format('truetype'),
    url('@shared/assets/fonts/SpaceGrotesk-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SpaceGrotesk';
  src: url('@shared/assets/fonts/SpaceGrotesk-Bold.eot');
  src:
    local('☺'),
    url('@shared/assets/fonts/SpaceGrotesk-Bold.woff') format('woff'),
    url('@shared/assets/fonts/SpaceGrotesk-Bold.ttf') format('truetype'),
    url('@shared/assets/fonts/SpaceGrotesk-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@import '@shared/assets/scss/style';

.app {
  &-header {
    display: none;
  }

  &-body {
    margin: 0 auto;
    background-color: $white;
  }

  @media (min-width: $size-medium) {
    .app-download-link-layout {
      padding-top: 40px;
      background-color: $white-5;
      min-height: calc(100% - 120px);
    }

    &-header {
      display: block;
      height: 80px;
      background-color: $white;
      border-bottom: 1px solid $gray-5;

      &::before {
        content: '';
        display: block;
        height: 100%;
        max-width: 784px;
        margin: 0 auto;
        background: url('@shared/assets/img/kaia_health_logo.svg') left center
          no-repeat;
        background-size: 120px 100px;
      }
    }
  }
}
</style>
