const trackDetailedViewScreen = (Tracker, screenName, corporate, utms) => {
  corporate = Object(corporate) === corporate ? corporate : {}

  utms = Object(utms) === utms ? utms : {}

  Tracker =
    Object(Tracker) === Tracker && typeof Tracker.trackKaiaEvent === 'function'
      ? Tracker
      : { trackKaiaEvent: () => {} }

  const payload = {
    corporate_id: corporate.id,
    corporate_name: corporate.title,
    corporate_type: corporate.corporateTypes?.toString() || '',
    corporate_channel: corporate.channel || null,
    utm_medium: utms.medium || null,
    utm_source: utms.source || null,
    utm_campaign: utms.campaign || null,
    utm_content: utms.content || null,
  }

  screenName = typeof screenName === 'string' ? screenName : '<unknown_screen>'

  Tracker.trackKaiaEvent(
    {
      event_name: 'cb.lfcycl.view_screen',
      app_area: 'lifecycle',
      action: 'view',
      object_type: 'screen',
      source: 'client_browser',
      screen_name: screenName,
    },
    {
      custom_payload: payload,
    },
  )
}

export default trackDetailedViewScreen
