import { createI18n } from 'petite-vue-i18n'

import messages from '@shared/lang'
import { getLangCodeByLanguage, getDefaultLanguage } from '@shared/utils'

const changeCookieDialogLocale = (locale) => {
  const element = document.getElementById('Cookiebot')
  if (element == null) {
    return
  }

  const langCode =
    (locale.startsWith('de') && 'DE') ||
    (locale.startsWith('es') && 'ES') ||
    'EN'
  element.setAttribute('data-culture', langCode)
  if (window.Cookiebot.renew) {
    window.Cookiebot.renew()
  }
}

const escapeTranslations = (translations) => {
  const transformedTranslations = { ...translations }
  Object.keys(transformedTranslations).forEach((key) => {
    transformedTranslations[key] = transformedTranslations[key].replace(
      /([$@|])/g,
      "{'$1'}",
    )
  })
  return transformedTranslations
}

const defaultLangCode = getLangCodeByLanguage(getDefaultLanguage())

for (const key in messages) {
  messages[key] = escapeTranslations(messages[key])
}
const i18n = createI18n({
  locale: defaultLangCode,
  legacy: false,
  fallbackLocale: defaultLangCode,
  messages,
  warnHtmlMessage: false,
})

export const assignLocaleMessage = (message, lang) => {
  const current = messages[lang] || {}
  i18n.global.setLocaleMessage(
    lang,
    Object.assign(current, escapeTranslations(message)),
  )
}

export const changeLocale = (locale, rootElement) => {
  i18n.global.locale.value = locale
  rootElement.setAttribute('lang', locale)
  changeCookieDialogLocale(locale)
}

export default i18n
