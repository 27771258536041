import store from '@msk-us/state/store'
import ROUTE from '@msk-us/router/appModule/names'
import VueCookieConsent from '@shared/VueCookieConsent'
import { isFromApp } from '@shared/helpers/isFromApp'
import preFillVouchers from '@msk-us/config/b2b-corporate-pre-fill-vouchers'
import downmarketLinks from '@msk-us/config/b2b-downmarket-links'
import startkaiaDeLinks from '@msk-us/config/b2b-startkaia-de-links'
import { decodeQuery, getCookie } from '@shared/utils.js'
import { saveAuthQueryToStore } from '@shared/api-client'
import SIGNUP_METHODS from '@msk-us/config/signup-methods.js'

const defaultSignUpMethod = SIGNUP_METHODS.VOUCHER

const preferredSignUpMethodMap = {
  [SIGNUP_METHODS.VOUCHER]: ROUTE.B2B_VOUCHER,
  [SIGNUP_METHODS.EMAIL]: ROUTE.B2B_EMAIL,
  [SIGNUP_METHODS.UHCHUB]: ROUTE.B2B_UHCHUB_VERIFICATION,
  [SIGNUP_METHODS.ELIGIBILITY_LIST]:
    ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION,
  [SIGNUP_METHODS.SOLERA]: ROUTE.B2B_SOLERA_VERIFICATION,
  [SIGNUP_METHODS.VIRGINPULSE_SSO]: ROUTE.SAML_SSO_INITIATED,
}

const getVerificationParams = ($route, $store) => (param) =>
  ($store.getters['b2b/verificationParams'] &&
    $store.getters['b2b/verificationParams'][param]) ||
  $route.query[param]

const isVerificationDataAvailable = (
  corporate,
  voucher,
  corporateEligibilityData,
) => {
  switch (corporate.preferredSignupMethod) {
    case SIGNUP_METHODS.VOUCHER:
      return voucher && voucher.code
    case SIGNUP_METHODS.EMAIL:
      return voucher && voucher.emailCode
    case SIGNUP_METHODS.ELIGIBILITY_LIST:
    case SIGNUP_METHODS.PARTICIPATION_ID:
    case SIGNUP_METHODS.SOLERA:
      return Object.keys(corporateEligibilityData || {}).length > 0
    default:
      return true
  }
}

const getCorporateKeyFromRoute = (route) =>
  (route.params.corp_name || route.query.corporate || '').toLowerCase()

export default async (routeTo, routeFrom, next) => {
  const redirectTo = (name, qs, params) =>
    next({ name, query: qs || routeTo.query, params: params || routeTo.params })
  let corporateName = getCorporateKeyFromRoute(routeTo)

  if (startkaiaDeLinks.includes(corporateName)) {
    window.location.replace(`https://startkaia.de/bp/${corporateName}`)
    return
  }

  switch (corporateName) {
    case 'vpt_social':
      return redirectTo(ROUTE.UHC_VPT_INSURANCE_SELECTION, {})
    case 'uhc_vpt_tile':
      return redirectTo(ROUTE.UHC_VPT_PRIMER, {})
  }

  VueCookieConsent(
    window,
    document,
    getCookie('CookieConsent'),
    store.getters.country,
    isFromApp(),
    store.getters.lang,
  )

  // handle routes that don't need a corporate object
  switch (routeTo.name) {
    case ROUTE.B2B:
      if (!corporateName) {
        return redirectTo(ROUTE.B2B_PARTNERS)
      }

      await store.dispatch(
        'b2b/storeVerificationParams',
        decodeQuery(routeTo.query),
      )

      if (downmarketLinks.includes(corporateName)) {
        return redirectTo(ROUTE.B2B_INFORMATION_GENERIC)
      }

      return redirectTo(
        ROUTE.B2B_INFORMATION,
        {},
        {
          corp_name: corporateName,
        },
      )
    case ROUTE.B2B_PARTNERS:
    case ROUTE.B2B_INFORMATION_GENERIC:
    case ROUTE.UHC_VPT_PRIMER:
    case ROUTE.UHC_VPT_INSURANCE_SELECTION:
    case ROUTE.UHC_VPT_EMPLOYER_SEARCH:
    case ROUTE.UHCHUB_INIT:
    case ROUTE.PERSONIFY_HEALTH_INIT:
      return next()
  }

  let corporate = null
  try {
    corporate = await store.dispatch('b2b/ensureCorporate', {
      name: corporateName,
    })
  } catch (e) {
    console.error(e)

    return redirectTo(
      ROUTE.B2B_PARTNERS,
      {},
      {
        corp_name: corporateName,
      },
    )
  }

  if (!corporate.isCustomer) {
    // TODO pass corporate key to partner search and show not customer warning there
    return redirectTo(
      ROUTE.B2B_PARTNERS,
      {},
      {
        corp_name: corporate.key,
      },
    )
  }

  const verificationParams = getVerificationParams(routeTo, store)

  // handle routes that need a corporate object
  switch (routeTo.name) {
    case ROUTE.B2B_DOWNLOAD_LINK:
      if (store.getters.user == null) {
        return redirectTo(ROUTE.B2B_LOGIN)
      }
      return next()

    case ROUTE.B2B_INFORMATION:
      // skip the information screen if the corporate has disabled extended onboarding
      if (!corporate.showExtendedOnboarding) {
        return redirectTo(ROUTE.B2B_SELECT_VERIFICATION_METHOD)
      }

      // skip the information screen if its a login flow
      if (
        verificationParams('login') === 'true' ||
        verificationParams('one_time_token') ||
        verificationParams('token') ||
        verificationParams('skip_information')
      ) {
        return redirectTo(
          ROUTE.B2B_SELECT_VERIFICATION_METHOD,
          {},
          {
            corp_name: corporateName,
          },
        )
      }
      break

    case ROUTE.B2B_SELECT_VERIFICATION_METHOD: {
      let voucherCode =
        verificationParams('voucher_code') || verificationParams('voucher')
      const emailCode = verificationParams('email_code')
      const email = verificationParams('email')

      let signUpMethod = corporate.preferredSignupMethod
      if (!Object.values(SIGNUP_METHODS).includes(signUpMethod)) {
        signUpMethod = defaultSignUpMethod
      }

      const preFillVoucher = preFillVouchers[corporate.key]
      if (!voucherCode && preFillVoucher && verificationParams('startkaia')) {
        signUpMethod = SIGNUP_METHODS.VOUCHER
        voucherCode = preFillVoucher
      }

      return next({
        ...routeTo,
        name: preferredSignUpMethodMap[signUpMethod],
        params: {
          corp_name: corporate.key,
          ...(signUpMethod === SIGNUP_METHODS.VOUCHER &&
            voucherCode && { voucherCode }),
          ...(signUpMethod === SIGNUP_METHODS.EMAIL &&
            emailCode && { emailCode }),
        },
        query: {
          ...routeTo.query,
          ...(email && { email }),
        },
      })
    }

    case ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION:
    case ROUTE.B2B_EMAIL:
      if (verificationParams('login') === 'true') {
        return redirectTo(
          ROUTE.B2B_VERIFY_NOT_POSSIBLE_FOR_EXISTING_USER,
          {},
          {
            corp_name: corporate.key,
          },
        )
      }
      break

    case ROUTE.SAML_SSO_INITIATED:
      if (store.getters.user) {
        return redirectTo(ROUTE.SAML_SSO_COMPLETED)
      }
      break

    case ROUTE.SAML_SSO_COMPLETED:
      try {
        if (routeTo.query.one_time_token) {
          await store.dispatch('loginWithOneTimeAuthToken', {
            token: routeTo.query.one_time_token,
          })
        } else {
          // todo remove this once we are fully migrated to one time tokens
          saveAuthQueryToStore(routeTo.query)
          await store.dispatch('getUser')
        }
      } catch (error) {
        let query = {}
        // TODO what other errors can happen that should not be handled with the default message?
        if (error.response?.status === 401) {
          query = { code: 'UNAUTHORIZED' }
        }
        return redirectTo(ROUTE.SAML_SSO_ERROR, query)
      }

      // Block the user from going any further without a valid subscription.
      // This should be removed as soon as subscriptions are handled correctly in the apps.
      if (!store.getters.user?.isPro) {
        return redirectTo(ROUTE.SAML_SSO_ERROR, {
          code: 'NO_ACTIVE_SUBSCRIPTION',
        })
      }

      if (store.getters.user?.profile?.hasAcceptedDataProtection) {
        return redirectTo(ROUTE.B2B_DOWNLOAD_LINK)
      }
      break

    case ROUTE.B2B_SIGN_UP: {
      const voucher = store.getters['b2b/voucher']
      const corporateEligibilityData = store.getters['b2b/corporateEligibility']

      if (
        verificationParams('login') === 'true' ||
        verificationParams('one_time_token')
      ) {
        return redirectTo(ROUTE.B2B_LOGIN)
      }

      if (
        !isVerificationDataAvailable(
          corporate,
          voucher,
          corporateEligibilityData,
        )
      ) {
        return redirectTo(ROUTE.B2B_SELECT_VERIFICATION_METHOD)
      }
      break
    }

    default:
      break
  }

  return next()
}
