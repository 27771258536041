<template>
  <div class="quiz-content__inner phq self-test">
    <div class="self-test__layout">
      <div>
        <div class="self-test__description">
          {{
            $t('b2b__self_test__phq__description', {
              steps: question.step,
            })
          }}
        </div>
        <h3 class="self-test__header">{{ $t(question.headerKey) }}</h3>
        <div
          class="self-test__description"
          v-html="$t('b2b__self_test__physical_activity__msk_us__items_hint')"
        />
        <fieldset class="form-container">
          <div
            v-for="item in items"
            :key="item.key"
            :class="[
              'quiz-radio',
              { 'quiz-radio--checked': item.value === phqSelectedValue },
            ]"
            @click="handleRadioButtonChange(item)"
          >
            <label class="quiz-radio__label kaia-p2-semi-bold">
              {{ $t(item.translation) }}
            </label>
            <div class="quiz-radio__layout">
              <custom-radio
                :value="item.key"
                :checked="item.value === phqSelectedValue"
                data-q-a="phq-selected-value"
                :data-q-a-value="item.key"
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div class="interactive-button__layout">
        <BaseButton
          :loading="interactiveButtonState === 'DISABLED'"
          :text="$t('generic_action_continue')"
          data-q-a="submit"
          @click="handleCTAButtonEvent"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import phqOptions from '@shared/modules/assessment/config/phq-options.json'
import ROUTE from '@shared/modules/assessment/router/names'

import CustomRadio from '@shared/components/CustomRadio.vue'
import BaseButton from '@shared/components/BaseButton.vue'

const props = defineProps({
  handleNextEvent: {
    type: Function,
    default: () => {},
  },
})

const route = useRoute()
const store = useStore()

const question = computed(() => {
  switch (route.name) {
    case ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHQ_INTEREST:
      return {
        headerKey: 'b2b__self_test__phq_interest__header',
        step: '1/9',
        getter: () => store.getters['assessment/phqInterest'],
        setter: (value) => store.commit('assessment/updatePHQInterest', value),
      }
    case ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PHQ_FEELING:
      return {
        headerKey: 'b2b__self_test__phq_feeling__header',
        step: '2/9',
        getter: () => store.getters['assessment/phqFeeling'],
        setter: (value) => store.commit('assessment/updatePHQFeeling', value),
      }
    case ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_NERVOUS:
      return {
        headerKey: 'b2b__self_test__gad_nervous__header',
        step: '3/9',
        getter: () => store.getters['assessment/gadNervous'],
        setter: (value) => store.commit('assessment/updateGADNervous', value),
      }
    case ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_CONTROL:
      return {
        headerKey: 'b2b__self_test__gad_control__header',
        step: '4/9',
        getter: () => store.getters['assessment/gadControl'],
        setter: (value) => store.commit('assessment/updateGADControl', value),
      }
    case ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_WORRYING:
      return {
        headerKey: 'b2b__self_test__gad_worrying__header',
        step: '5/9',
        getter: () => store.getters['assessment/gadWorrying'],
        setter: (value) => store.commit('assessment/updateGADWorrying', value),
      }
    case ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_RELAXING:
      return {
        headerKey: 'b2b__self_test__gad_relaxing__header',
        step: '6/9',
        getter: () => store.getters['assessment/gadRelaxing'],
        setter: (value) => store.commit('assessment/updateGADRelaxing', value),
      }
    case ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_RESTLESS:
      return {
        headerKey: 'b2b__self_test__gad_restless__header',
        step: '7/9',
        getter: () => store.getters['assessment/gadRestless'],
        setter: (value) => store.commit('assessment/updateGADRestless', value),
      }
    case ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_IRRITABLE:
      return {
        headerKey: 'b2b__self_test__gad_irritable__header',
        step: '8/9',
        getter: () => store.getters['assessment/gadIrritable'],
        setter: (value) => store.commit('assessment/updateGADIrritable', value),
      }
    case ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_GAD_AFRAID:
      return {
        headerKey: 'b2b__self_test__gad_afraid__header',
        step: '9/9',
        getter: () => store.getters['assessment/gadAfraid'],
        setter: (value) => store.commit('assessment/updateGADAfraid', value),
      }
    default:
      return null
  }
})

const phqSelectedValue = computed(() => question.value.getter())

const items = ref(phqOptions.concat([]))

const interactiveButtonState = ref(
  phqSelectedValue.value > -1 ? 'ACTIVE' : 'DISABLED',
)

const handleRadioButtonChange = (item) => {
  interactiveButtonState.value = 'ACTIVE'
  question.value.setter(item.value)
}

const handleCTAButtonEvent = (event) => {
  interactiveButtonState.value = 'DISABLED'
  props.handleNextEvent(event)
}
</script>

<style lang="scss">
@import '@shared/modules/assessment/views/css/SelfTest';
.phq {
  .self-test__layout {
    @media (max-width: $breakpoint-mobile) {
      padding-bottom: 4px;
    }
  }
  .self-test__header {
    margin: 16px 0 32px;
  }
  .self-test__description {
    margin: 0;
  }
  .interactive-button__layout {
    margin: 32px auto 0;
  }
  .form-container {
    margin-top: 8px;
  }
}
</style>
