export default (
  window,
  document,
  cookieConsentValue,
  country,
  isFromApp,
  lang,
) => {
  let cookieConsentAccepted = null

  try {
    cookieConsentAccepted = new Event('cookieConsentAccepted')
  } catch {
    cookieConsentAccepted = document.createEvent('Event')
    cookieConsentAccepted.initEvent('cookieConsentAccepted', false, false)
  }

  if (cookieConsentValue != null || country === 'US' || isFromApp) {
    document.dispatchEvent(cookieConsentAccepted)
    return
  }

  const s = document.createElement('script')
  s.id = 'Cookiebot'
  s.async = true
  if (typeof lang === 'string') {
    const langCode =
      (lang.startsWith('de') && 'DE') || (lang.startsWith('es') && 'ES') || 'EN'
    s.setAttribute('data-culture', langCode)
  }
  s.setAttribute('data-cbid', '7d811d12-5575-4e58-8290-0f98ab306a9a')
  s.setAttribute('type', 'text/javascript')
  s.src = 'https://consent.cookiebot.com/uc.js'
  s.onload = function load() {
    s.onload = null

    window.Cookiebot.show()

    const oldOnAccept = window.Cookiebot.onaccept
    window.Cookiebot.onaccept = function onaccept() {
      window.Cookiebot.onaccept = oldOnAccept
      document.dispatchEvent(cookieConsentAccepted)

      window.Cookiebot.show = function show() {}
    }
  }

  const fs = document.getElementsByTagName('script')[0]
  fs.parentNode.insertBefore(s, fs)
}
