const env = {}

for (const [key, value] of Object.entries(import.meta.env)) {
  if (key.startsWith('VITE_')) {
    env[key] = value
  }
}

export const isMskEu = () => env.VITE_GEO === 'eu' && env.VITE_DISEASE === 'msk'
export const isMskUs = () => env.VITE_GEO === 'us' && env.VITE_DISEASE === 'msk'
export const isCopdEu = () =>
  env.VITE_GEO === 'eu' && env.VITE_DISEASE === 'copd'

export default env
