<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="uhc-vpt-primer">
    <h1 class="kds-typography-display-medium">
      {{ $t('uhc_vpt_primer__hero__title') }}
    </h1>
    <p class="kds-typography-paragraph-large">
      {{ $t('uhc_vpt_primer__hero__description') }}
    </p>
    <div class="uhc-vpt-primer__wrapper">
      <div class="uhc-vpt-primer__wrapper--content">
        <p
          class="uhc-vpt-primer__wrapper--content-description"
          v-html="$t('uhc_vpt_primer__hero__content')"
        />
        <BaseButton
          ref="ctaButtonRef"
          class="uhc-vpt-primer__wrapper--content--cta"
          @click="handleCTAButtonClick"
        >
          {{ ctaBtnTitle }}
        </BaseButton>
      </div>
      <AssetImage
        class="start-journey__container__image"
        :name="image"
      />
    </div>
  </div>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import AssetImage from '@shared/components/AssetImage.vue'
import BaseButton from '@shared/components/BaseButton.vue'

const props = defineProps({
  ctaBtnTitle: {
    type: String,
    default: '',
  },
  handleCTAButtonClick: {
    type: Function,
    default: () => {},
  },
  receiveCTAButtonClientRect: {
    type: Function,
    default: () => {},
  },
})

const store = useStore()

const ctaButtonRef = ref()
const lang = computed(() => store.getters.lang)

const image = computed(() => {
  if (lang.value === 'es-US') {
    return 'vpt-primer-main-img-es-US.jpg'
  }

  return 'vpt-primer-main-img-en-US.jpg'
})

onMounted(() =>
  props.receiveCTAButtonClientRect(() =>
    ctaButtonRef?.value?.button?.getBoundingClientRect(),
  ),
)
</script>

<style lang="scss" scoped>
.uhc-vpt-primer {
  @include flex-container(column, center, md, center);
  @include padding(md);
  text-align: center;
  max-width: 1026px;
  margin: auto;

  &__wrapper {
    @include flex-container(column, center, md, center);

    @include media-up(lg) {
      @include grid-container(1fr 328px, auto, xxl);
    }

    > img {
      width: 100%;
      height: 355px;
      object-fit: contain;
    }

    &--content {
      text-align: start;
      @include flex-container(column, center, md);

      &-description {
        @include kds-typography-title-large(
          $kds-typography-font-weight-regular
        );
      }

      &--cta {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>
