<template>
  <div class="mobile-header">
    <div class="content mobile-header-content">
      <img
        src="@shared/assets/img/logo-mobile.svg"
        alt="logo"
      />
      <h6 v-html="$t('b2b_logo_text')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileHeader',
}
</script>

<style lang="scss" scoped>
.mobile-header {
  display: none;
  background-color: $sky;

  .mobile-header-content {
    padding-top: 17px;
    padding-bottom: 17px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    img {
      width: 85px;
      height: 34px;
      margin-right: 24px;
    }

    h6 {
      margin: unset;
      max-width: 295px;
      text-align: end;
    }

    @media (max-width: $breakpoint-mobile) {
      margin: 17px 24px;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    display: flex;
  }
}
</style>
