<template>
  <div class="dots-loader">
    <span class="dots-loader__item" />
  </div>
</template>

<script>
export default {
  name: 'DotsLoader',
}
</script>

<style lang="scss">
@import '@msk-us/components/css/DotsLoader.scss';
</style>
