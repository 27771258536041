<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.257 7.08333L12.0541 2.17991C11.7246 1.79553 11.7691 1.21683 12.1535 0.887364C12.5379 0.557894 13.1166 0.602409 13.4461 0.986791L18.9461 7.40346C19.2403 7.74674 19.2403 8.25329 18.9461 8.59657L13.4461 15.0132C13.1166 15.3976 12.5379 15.4421 12.1535 15.1127C11.7691 14.7832 11.7246 14.2045 12.0541 13.8201L16.2571 8.91667L1.75008 8.91667C1.24382 8.91667 0.833416 8.50626 0.833416 8C0.833416 7.49374 1.24382 7.08333 1.75008 7.08333L16.257 7.08333Z"
      fill="currentColor"
    />
  </svg>
</template>
