import { computed, useSlots } from 'vue'

/**
 * @param availableSlots {string[]} Array of slots that could exist in this component
 * @returns hasSlot Computed property that determines if a slot exists or not.
 * Use like so: v-if="hasSlot['slot-name']"
 */
export default function useVueSlots(availableSlots) {
  const slots = useSlots()

  const hasSlot = computed(() => {
    return availableSlots.reduce((accum, slot) => {
      // as hasOwn is not supported in all browser that we support we need to use the more ugly hasOwnProperty workaround
      //accum[slot] = Object.hasOwn(slots, slot) ?? false
      accum[slot] = Object.prototype.hasOwnProperty.call(slots, slot) ?? false
      return accum
    }, {})
  })

  return { hasSlot }
}
