<template>
  <img
    class="base64-spinner"
    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEiIHZpZXdCb3g9IjAgMCAxMjggMTI4Ij4KICA8ZyBjbGFzcz0ic3Bpbm5lciI+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImEiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjZmZmIiBmaWxsLW9wYWNpdHk9IjAiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjNDE4MEQyIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogICAgPHBhdGggZD0iTTYzLjg1IDBBNjMuODUgNjMuODUgMCAxIDEgMCA2My44NSA2My44NSA2My44NSAwIDAgMSA2My44NSAwem0uNjUgMTkuNWE0NCA0NCAwIDEgMS00NCA0NCA0NCA0NCAwIDAgMSA0NC00NHoiCiAgICAgIGZpbGw9InVybCgjYSkiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgogIDwvZz4KICA8c3R5bGU+CiAgICBAa2V5ZnJhbWVzIHNwaW5Mb2FkZXIgewogICAgICAwJSB7CiAgICAgICAgdHJhbnNmb3JtOiByb3RhdGUoMHR1cm4pOwogICAgICB9CiAgICAgIHRvIHsKICAgICAgICB0cmFuc2Zvcm06IHJvdGF0ZSgxdHVybik7CiAgICAgIH0KICAgIH0KCiAgICAuc3Bpbm5lciB7CiAgICAgIHRyYW5zZm9ybS1vcmlnaW46IGNlbnRlcjsKICAgICAgYW5pbWF0aW9uOiBzcGluTG9hZGVyIC40OHMgc3RlcHMoMTIpIGluZmluaXRlOwogICAgfQogIDwvc3R5bGU+Cjwvc3ZnPgo="
  />
</template>
<script>
export default {
  name: 'Base64Spinner',
}
</script>
<style lang="scss">
@import '@shared/components/css/Base64Spinner.scss';
</style>
