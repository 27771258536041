<template>
  <div class="partner-search-media">
    <div class="header">
      <AppLogo>{{ $t('b2b__partner_search__media_header') }}</AppLogo>
    </div>

    <div class="image-wrap">
      <img
        class="image-wrap__banner"
        src="@shared/assets/img/b2b/partner-search-media.jpg"
      />
      <img
        width="100%"
        src="@shared/assets/img/b2b/partner-search-media_0.5x.png"
      />
    </div>
  </div>
</template>

<script>
import AppLogo from '@shared/components/AppLogo.vue'

export default {
  name: 'PartnerSearchMedia',
  components: {
    AppLogo,
  },
}
</script>

<style lang="scss">
@import './css/PartnerSearchMedia.scss';
</style>
