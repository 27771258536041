<template>
  <FullPagePreloader v-if="showPreloader" />
  <div
    v-else
    class="confirmation-content"
  >
    <MobileHeader />
    <div class="content">
      <div class="content-header">
        <p
          class="kaia-p3-bold"
          v-html="$t('assessment_completion_title')"
        />
      </div>
      <p
        class="kaia-p2-bold"
        v-html="$t('assessment_completion_description')"
      />
      <a
        v-if="deepLink"
        :href="deepLink"
        class="btn btn-primary"
        v-html="$t('b2b_goto_app_btn')"
      />
    </div>
  </div>
</template>

<script>
import MobileHeader from '@shared/components/MobileHeader.vue'
import { isFromApp } from '@shared/helpers/isFromApp'
import { isMobile } from '@shared/utils'
import FullPagePreloader from '@shared/components/FullPagePreloader.vue'
import { sessionStorage } from '@shared/helpers/storage'
import assembleKaiaAppDeepLink from '@shared/helpers/assembleKaiaAppDeepLink'

export default {
  name: 'Completion',
  components: {
    MobileHeader,
    FullPagePreloader,
  },
  data() {
    return {
      deepLink: '',
      showPreloader: false,
    }
  },
  created() {
    this.showFullPagePreloader(isMobile() || isFromApp())
  },
  async mounted() {
    if (isMobile() || isFromApp()) {
      const completedInitialAssessment =
        sessionStorage.getItem('assessment:previousQuestionnaire') ===
        'self_test'

      this.showFullPagePreloader()
      this.deepLink = await assembleKaiaAppDeepLink(
        'trigger/assessment_completed',
        { ...(completedInitialAssessment ? { type: 'initial' } : {}) },
      )
      setTimeout(() => {
        this.showFullPagePreloader(false)
      }, 1000)

      this.redirectToApp(this.deepLink)
    }
  },
  beforeUnmount() {
    this.showFullPagePreloader(false)
  },
  methods: {
    showFullPagePreloader(shouldShow = true) {
      this.showPreloader = shouldShow
    },
    redirectToApp(link) {
      window.location.href = link
    },
  },
}
</script>

<style lang="scss" scoped>
.confirmation-content {
  .content-header {
    margin-top: 60px;
    margin-bottom: 32px;
  }

  a {
    margin-top: 32px;
  }
}
</style>
