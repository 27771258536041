<template>
  <div class="app-logo">
    <img
      src="@shared/assets/img/logo-midnight.svg"
      alt="logo"
    />
    <div class="app-logo-inner kaia-p3-bold">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLogo',
}
</script>

<style lang="scss" scoped>
.app-logo {
  display: flex;
  align-items: center;

  img {
    margin-right: 20px;
  }

  .app-logo-inner {
    max-width: 295px;
  }
}
</style>
