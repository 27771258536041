<template>
  <div class="accordion">
    <AccordionItem
      v-for="({ title, content }, index) in items"
      :key="index"
      :title="title"
      :content="content"
      @click="(open) => emit('click', { open, index })"
    >
    </AccordionItem>
  </div>
</template>

<script setup>
import AccordionItem from '@shared/components/AccordionItem.vue'

const emit = defineEmits(['click'])

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
})
</script>

<style lang="scss">
.accordion {
  scrollbar-width: none;
}
</style>
