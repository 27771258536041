<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 11C2.5 13.3333 6.8 18 12 18C17.2 18 21.5 13.3333 23 11"
      stroke="#515559"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="4.2"
      y1="15.4"
      x2="2.4"
      y2="17.8"
      stroke="#515559"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="9.55709"
      y1="18.2999"
      x2="8.44291"
      y2="21.0853"
      stroke="#515559"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="15.2999"
      y1="18.5571"
      x2="16.414"
      y2="21.3425"
      stroke="#515559"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="20.4"
      y1="15.2"
      x2="22.2"
      y2="17.6"
      stroke="#515559"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
