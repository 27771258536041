import * as apiRequest from '@shared/api-endpoints'
import {
  getLangCodeByLanguage,
  getSupportedLangCodes,
  normalizeLang,
} from '@shared/utils'
import { changeLocale } from '@shared/localization'
import User from '@shared/data/models/user'

const applyUserLanguage = ({ commit }, userLanguage) => {
  const supportedLangCodes = getSupportedLangCodes()
  let langCode = getLangCodeByLanguage(userLanguage)
  if (!supportedLangCodes.includes(langCode)) {
    langCode = supportedLangCodes[0]
    userLanguage = normalizeLang(langCode)
  }
  changeLocale(langCode, document.documentElement)
  commit('lang', userLanguage)
}

const storeSession = async ({ commit, dispatch }, response) => {
  const userData = response.data?.user
  const user = userData && new User(userData) // main entrypoint for the user into the store

  commit('user', user)
  applyUserLanguage({ commit }, user?.profile?.language)

  const corporateKey = user?.currentSubscription?.corporateKey

  if (corporateKey) {
    await dispatch('b2b/ensureCorporate', { name: corporateKey })
  }

  return user
}

const login = ({ dispatch }, { email, password, stayLoggedIn }) =>
  apiRequest
    .login(email, password, stayLoggedIn)
    .then((data) => dispatch('storeSession', data))

const getUser = ({ dispatch }) =>
  apiRequest.getUser().then((data) => dispatch('storeSession', data))

const setUserEmail = (_, email) => apiRequest.setUserEmail(email)

const setUserProfile = ({ dispatch }, profile) =>
  apiRequest
    .setUserProfile(profile)
    .then((data) => dispatch('storeSession', data))

const signUp = ({ dispatch }, payload) =>
  apiRequest.signUp(payload).then((data) => dispatch('storeSession', data))

const resetPassword = (_, { email }) => apiRequest.resetPassword({ email })

const setLang = ({ commit }, lang) => {
  commit('lang', lang)
}

const loginWithOneTimeAuthToken = ({ dispatch }, { token }) =>
  apiRequest
    .loginWithOneTimeAuthToken(token)
    .then((data) => dispatch('storeSession', data))

export default {
  login,
  getUser,
  setUserEmail,
  setUserProfile,
  signUp,
  resetPassword,
  setLang,
  storeSession,
  loginWithOneTimeAuthToken,
}
