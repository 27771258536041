export default (needle, title) => {
  if (typeof needle !== 'string' || typeof title !== 'string') {
    return ''
  }

  if (!needle || !title) {
    return ''
  }

  const titleChunks = title.split(/\s/g)

  needle = needle.split(/\s/g)
  if (needle.length === 1) {
    needle = needle[0].split('')
  }

  if (needle.length !== titleChunks.length) {
    return ''
  }

  for (let i = 0; i < titleChunks.length; ++i) {
    if (titleChunks[i].toLowerCase().indexOf(needle[i].toLowerCase()) === 0) {
      titleChunks[i] = `<strong>${titleChunks[i].slice(
        0,
        needle[i].length,
      )}</strong>${titleChunks[i].slice(needle[i].length)}`
    } else {
      return ''
    }
  }

  return titleChunks.join(' ')
}
