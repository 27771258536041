import { getField, updateField } from '@shared/vuex-utils'
import * as apiRequest from '@shared/api-endpoints'
import { getLangCodeByLanguage } from '@shared/utils'
import { assignLocaleMessage } from '@shared/localization'
import { sessionStorage } from '@shared/helpers/storage'
import { isFromApp } from '@shared/helpers/isFromApp'
import getUrlTrackingRecord from '@msk-us/helpers/getUrlTrackingRecord'
import Corporate from '@shared/data/models/corporate'
import groupVerificationConfig from '@msk-us/config/b2b-group-verification.json'

const eligibilitySessionHashKey = 'ELIGIBILITY_ATTEMPT_HASH'

const storeVerificationParams = ({ commit }, params) => {
  commit('verificationParams', { ...params })
  if (params.eligibility_session_hash !== undefined) {
    sessionStorage.setItem(
      eligibilitySessionHashKey,
      params.eligibility_session_hash,
    )
  }
}

/**
 * returns the eligibility session hash and sets it if it is not set yet
 * @param {string} corporateKey - the corporate key to encode in the hash
 * @returns {string} the eligibility session hash
 */
const getOrSetEligibilitySessionHash = (corporateKey) => {
  let hash = getEligibilitySessionHash()
  if (hash === null) {
    hash = btoa(`${corporateKey}${Date.now()}`)
    sessionStorage.setItem(eligibilitySessionHashKey, hash)
  }
  return hash
}

/**
 * @returns {string | null} the eligibility session hash or null if it is not set
 */
export const getEligibilitySessionHash = () =>
  sessionStorage.getItem(eligibilitySessionHashKey)

const signUp = ({ commit, dispatch, getters }, payload) => {
  payload.eligibilitySessionHash = getEligibilitySessionHash()
  return dispatch(
    'signUp',
    {
      ...payload,
      fromApp: isFromApp(),
      corporateEligibilityData: getters.corporateEligibility,
      signupContext: getters.signupContext,
    },
    { root: true },
  ).then(() => commit('corporateEligibility', {}))
}

const voucherToStore = (commit) => (voucher) => {
  commit('voucher', voucher)
  return voucher
}

const checkVoucher = ({ commit }, code) =>
  apiRequest
    .checkVoucherB2b(code)
    .then(({ data }) => data.corporate_voucher)
    .then(voucherToStore(commit))

const getCorporateEligibilityConfig = (_, { corporateKey, lang }) => {
  if (corporateKey in groupVerificationConfig) {
    return apiRequest
      .getCorporateEligibilityGroupConfig(
        groupVerificationConfig[corporateKey].groupId,
        lang,
      )
      .then((res) => res.data.corporate_eligibility_group_config)
  }

  return apiRequest
    .getCorporateEligibilityConfig(corporateKey, lang)
    .then((res) => res.data.corporate_eligibility_config)
}

/**
 * Internal function
 * Takes the verification data from the user input and check it against the
 * eligibility list of one corporate (defined by the corporate_key in the
 * corporateEligibilityData)
 */
const checkCorporateOrGroupEligibility = (
  { commit },
  corporateEligibilityData,
) => {
  const groupConfig =
    groupVerificationConfig[corporateEligibilityData.corporate_key]
  if (groupConfig !== undefined) {
    corporateEligibilityData.corporate_eligibility_group_id =
      groupConfig.groupId
    return apiRequest
      .checkCorporateGroupEligibility(corporateEligibilityData)
      .then((res) =>
        // TODO confirm that we actually want to redirect to the corporate that is associated to the eligibility list entry
        commit('corporateEligibility', {
          ...corporateEligibilityData,
          corporate_key: res.data.corporate_key,
        }),
      )
  }

  return apiRequest
    .checkCorporateEligibility({
      ...corporateEligibilityData,
    })
    .then(() => commit('corporateEligibility', corporateEligibilityData))
    .catch((e) => {
      commit('corporateEligibility', {})
      throw e
    })
}

/**
 * Internal function
 * Takes verification data from the user input and checks it against the
 * eligibility lists of the corporates and groups listed in the list param
 */
const checkEligibilityForListOfCorporatesAndGroups = (
  { commit },
  corporateEligibilityData,
  list,
) => {
  return checkCorporateOrGroupEligibility(
    { commit },
    {
      ...corporateEligibilityData,
      // inject the corporate of the current list to check into the payload
      corporate_key: list[0],
    },
  ).catch((e) => {
    // if there are other lists to check and no entry in the current list was a
    // match check the next list (recursion) otherwise return the original error
    if (list.length > 1 && e?.response?.data?.errors?.[0] === 'NOT_ELIGIBLE') {
      commit('corporateEligibility', {})
      return checkEligibilityForListOfCorporatesAndGroups(
        { commit },
        corporateEligibilityData,
        list.slice(1),
      )
    } else {
      throw e
    }
  })
}

/**
 * The only eligibility check function that should be called from the outside
 * Takes verification data from the user input and checks against all relevant
 * eligibility lists (in sequence)
 * Which lists to check is defined in the groupVerificationConfig
 */
const checkCorporateEligibility = ({ commit }, corporateEligibilityData) => {
  // ensure that all checks will have the same eligibility session hash
  corporateEligibilityData.eligibility_session_hash =
    getOrSetEligibilitySessionHash(corporateEligibilityData.corporate_key)

  const groupConfig =
    groupVerificationConfig[corporateEligibilityData.corporate_key]

  // identify which eligibility lists to check (or rather the lists of which corporates or groups to check)
  const listsToCheck = [
    corporateEligibilityData.corporate_key,
    ...(groupConfig?.otherEligibilityListsToCheck ?? []),
  ]

  return checkEligibilityForListOfCorporatesAndGroups(
    { commit },
    corporateEligibilityData,
    listsToCheck,
  )
}

const checkEligibilityWithToken = (_, { corporate_key, eligible_token }) =>
  apiRequest.checkEligibilityWithEligibleToken(
    getOrSetEligibilitySessionHash(corporate_key),
    eligible_token,
  )

// helper
// TODO check if this is needed or can be simplified by merging into checkEmail
const createEmailCodeVoucher = (email) => (corporate) => ({
  emailCode: email,
  corporate_id: corporate.id,
  corporate_name: corporate.key,
})

const checkEmail = ({ commit }, { email, corporate }) =>
  apiRequest
    .checkEmailB2b(email, corporate)
    .then(({ data }) => data)
    .then(createEmailCodeVoucher(email))
    .then(voucherToStore(commit))

const assignTranslations = ({ corporate, lang }) => {
  assignLocaleMessage(corporate.translations, getLangCodeByLanguage(lang))
  return corporate
}

const corporateToStore =
  ({ commit }) =>
  (corporate) => {
    commit('corporate', corporate)
    return corporate
  }

const utmsToStore = ({ commit }, queryString) => {
  const utmData = {
    referrer: document.referrer,
  }
  const trackingRecord = getUrlTrackingRecord(queryString)
  for (let i in trackingRecord.record) {
    utmData[i] = trackingRecord.record[i]
  }

  commit('utmData', utmData)
}

const updateCorporate = (context, lang, corporate) =>
  Promise.resolve(assignTranslations({ lang, corporate })).then(
    corporateToStore(context),
  )

const fetchCorporate = (context, { name, lang, isLangSelected = false }) => {
  const selectedLang = (isLangSelected && lang) || context.rootGetters.lang
  return apiRequest
    .getCorporate(name, selectedLang)
    .then(({ data }) => new Corporate(data))
    .then((corporateInstance) =>
      updateCorporate(context, selectedLang, corporateInstance),
    )
}

const ensureCorporate = (context, { name }) => {
  const { corporate } = context.getters
  const { lang } = context.rootGetters
  return corporate && corporate.key === name
    ? updateCorporate(context, lang, corporate)
    : fetchCorporate(context, { name, lang })
}

const subscribeUserWithVoucher = ({ dispatch }, payload) =>
  apiRequest
    .subscribeB2BUserWithVoucher(payload)
    .then(() => dispatch('getUser', {}, { root: true }))

const subscribeUserWithInsuranceToken = (
  { dispatch },
  { corporateKey, token, type },
) =>
  apiRequest
    .subscribeWithInsuranceToken(corporateKey, token, type)
    .then(() => dispatch('getUser', {}, { root: true }))

const subscribeUserWithEligibleToken = (
  { dispatch },
  { corporate_key, eligible_token },
) =>
  apiRequest
    .subscribeWithEligibleToken(
      getOrSetEligibilitySessionHash(corporate_key),
      eligible_token,
    )
    .then(() => dispatch('getUser', {}, { root: true }))

export default {
  namespaced: true,
  state: {
    voucher: null,
    /** @type {Corporate} */
    corporate: null,
    // TODO rename to initialQuery (or something similar).
    // This should not be used to blindly fill any data that is send to the backend
    verificationParams: {},
    corporateEligibilityData: {},
    redirectLink: {},
    utmData: {
      source: null,
      medium: null,
      campaign: null,
      content: null,
      term: null,
      referrer: null,
    },
    formData: {
      acceptData: false,
      acceptPhoneCall: false,
      acceptTerms: false,
      acceptTracking: false,
      confirmed: false,
      corporateEligibilityData: {},
      dateOfBirth: '',
      email: '',
      emailCode: '',
      lastName: '',
      firstName: '',
      password: '',
      phoneNumber: '',
      stayLoggedIn: false,
      voucherCode: '',
    },
    signupContext: {
      webEntryPointUrl: '',
      webEntryPointReferrerUrl: '',
    },
  },
  mutations: {
    updateField,
    voucher(state, value) {
      state.voucher = value
    },
    corporate(state, value) {
      state.corporate = value
    },
    verificationParams(state, value) {
      state.verificationParams = value
    },
    corporateEligibility(state, value) {
      state.corporateEligibility = value
    },
    setFormData(state, value) {
      state.formData = { ...state.formData, ...value }
    },
    setFormDataCorporateEligibilityData(state, value) {
      state.formData.corporateEligibilityData = value
    },
    utmData(state, value) {
      state.utmData = value
    },
    signupContext(state, { webEntryPointUrl, webEntryPointReferrerUrl }) {
      // only update the fields in the signup context if they were not set yet
      state.signupContext.webEntryPointUrl ||= webEntryPointUrl
      state.signupContext.webEntryPointReferrerUrl ||= webEntryPointReferrerUrl
    },
    redirectLink(state, value) {
      state.redirectLink = value
    },
  },
  getters: {
    getField,
    voucher: (state) => state.voucher,
    corporate: (state) => state.corporate,
    utmData: (state) => state.utmData,
    verificationParams: (state) => state.verificationParams,
    corporateEligibility: (state) => state.corporateEligibility,
    formData: (state) => state.formData,
    formDataCorporateEligibilityData: (state) =>
      state.formData.corporateEligibilityData,
    phoneNumber: (state) => state.formData.phoneNumber,
    signupContext: (state) => state.signupContext,
    redirectLink: (state) => state.redirectLink,
  },
  actions: {
    signUp,
    checkVoucher,
    fetchCorporate,
    ensureCorporate,
    subscribeUserWithVoucher,
    subscribeUserWithInsuranceToken,
    subscribeUserWithEligibleToken,
    checkEmail,
    getCorporateEligibilityConfig,
    checkCorporateEligibility,
    checkEligibilityWithToken,
    storeVerificationParams,
    utmsToStore,
  },
}
