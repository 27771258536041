/*
This is an abstract implementation of model
Instantiating children of this class would create new models from json data

Example:

class RealModel extends AbstractModel {
}

instance = new RealModel({id: 1, email: "test@email.com", sup: { id: 2 }})
instance.id
  > 1
instance.email
  > "test@email.com"
instance.subscription
  > CurrentSubscription({ id: 2 })
instance.subscription.id
  > 2
*/

import utilsAssertType from './assertType'

class AbstractModel {
  // permanent reference to data that this instance was constructed with
  // even if actual instance properties were mutated somehow, we can always reset to initial state
  /**
   * @type {json}
   */
  _json = {}

  constructor(json) {
    this._json = json
  }

  /**
   * Validate the state of the instance
   * call after all properties are initialized
   * HINT: use this.assertType in order to check the types and raise warnings / errors consistently
   */
  validate() {}

  /**
   * Validates the attribute type
   * Right now we are not raising errors, only write to log in case type is incorrect
   *
   * @param {*} attrName name of the attribute to check the type
   * @param {Array} [expectedTypes=[]] array of possible types for this attribute
   */
  assertType(attrName, expectedTypes = []) {
    try {
      utilsAssertType(this[attrName], expectedTypes)
    } catch (error) {
      if (error instanceof TypeError) {
        console.warn(
          "Data validation error for '#" +
            attrName +
            "' on " +
            this.constructor.name +
            "' \n" +
            error,
        )
      } else {
        throw error
      }
    }
  }

  /**
   * Parse date from string if possible. Otherwise return null
   * @param {string} val
   * @returns {Date | null}
   */
  parseDate(val) {
    if (!val) return null

    const parsedDate = new Date(val)

    return !isNaN(parsedDate) && parsedDate
  }
}

export default AbstractModel
