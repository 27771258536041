<template>
  <div class="password-input">
    <TextField
      v-model="model"
      :label="$t(label)"
      :data-qa="dataQa"
      :errors="showPasswordRequirements ? [] : errors"
      :has-errors="errors.length > 0"
      v-bind="{ type, ...$attrs }"
      @focus="handleFocus"
      @blur="handleBlur"
    >
      <template #append>
        <component
          :is="visibilityIcon"
          @click="toggleTextVisibility"
        />
      </template>
    </TextField>
    <VerticalShowHideTransition :visible="shouldShowPasswordRequirements">
      <ul class="password-input__password-requirements">
        <li
          v-for="(requirement, index) in passwordRequirements"
          :key="index"
          class="password-input__password-requirements--input"
          :data-qa="requirement"
          :class="{
            valid: model && !passwordValidation.includes(requirement),
            invalid: model && passwordValidation.includes(requirement),
            error:
              passwordValidation.includes(requirement) && errors.length > 0,
          }"
        >
          {{ $t(requirement) }}
        </li>
      </ul>
    </VerticalShowHideTransition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { password } from '@shared/helpers/validations.js'
import TextField from './TextField.vue'
import EyesClosedIcon from '@shared/assets/icon/EyesClosedIcon.vue'
import EyesOpenIcon from '@shared/assets/icon/EyesOpenIcon.vue'
import VerticalShowHideTransition from '@shared/components/VerticalShowHideTransition.vue'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  dataQa: {
    type: String,
    default: '',
  },
  showPasswordRequirements: {
    // when true - we would display password requirements section. Otherwise we are not showing it
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: 'generic_field_password',
  },
})

const type = ref('password')
const focused = ref(false)

const model = defineModel({ type: String, default: '' })

const visibilityIcon = computed(() => {
  if (type.value === 'password') {
    return EyesClosedIcon
  }

  return EyesOpenIcon
})

// display hints when field is active or not empty and showPasswordRequirements is set to `true`
// @return {Boolean}
const shouldShowPasswordRequirements = computed(
  () =>
    props.showPasswordRequirements &&
    (focused.value || props.errors.length > 0),
)

const handleFocus = () => {
  focused.value = true
}

const handleBlur = () => {
  focused.value = false
}

const passwordRequirements = [
  'generic__password_field__hint__min_length',
  'generic__password_field__hint__upper_case',
  'generic__password_field__hint__lower_case',
  'generic__password_field__hint__number_or_special',
]

const passwordValidation = computed(() => password(model.value))

const toggleTextVisibility = () => {
  if (type.value === 'password') {
    type.value = 'text'
  } else {
    type.value = 'password'
  }
}
</script>

<style lang="scss">
.password-input {
  .wrapper {
    &__container {
      @include flex-container(row, space-between, md, center);
      @include padding(sm, right);

      &--field {
        flex-grow: 1;
      }

      &--append {
        display: flex;
        align-content: center;
        height: 100%;
      }
    }
  }

  &__password-requirements {
    @include grid-container(1fr 1fr, auto, xs);
    @include margin(xs);
    @include padding(0);

    list-style: none;

    @include media-up(md) {
      @include grid-container(repeat(4, 1fr), auto, md);
    }

    &--input {
      @include kds-typography-paragraph-small;
      text-decoration: none;
      text-wrap: nowrap;

      &.valid {
        color: $body-color;

        &:before {
          content: '✓';
          color: $green-highlight;
        }
      }

      &.error {
        color: $danger;
      }

      &:before {
        content: '✕';
        margin-right: 5px;
      }
    }
  }
}

.field__container__text-field--append {
  @include flex-container(column, align-center);
}
</style>
