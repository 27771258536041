<template>
  <div class="accordion-item">
    <div
      class="accordion-item__header"
      tabindex="0"
      @keypress.enter="toggle"
      @click="toggle"
    >
      <slot name="title">
        <p class="accordion-item__header--title">
          {{ title }}
        </p>
      </slot>
      <ChevronDown
        class="accordion-item__header--trigger"
        :class="{
          open,
        }"
      />
    </div>
    <Transition name="accordion">
      <div
        v-if="open"
        class="accordion-item__content"
      >
        <slot>
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="accordion-item__content--text"
            v-html="content"
          ></p>
          <!-- eslint-enable vue/no-v-html -->
        </slot>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import ChevronDown from '@shared/assets/icon/ChevronDown.vue'

const open = ref(false)
const emit = defineEmits(['click'])

defineProps({
  title: {
    type: String,
    default: '',
  },
  content: {
    type: String,
    default: '',
  },
})

const toggle = () => {
  open.value = !open.value
  nextTick(() => {
    emit('click', open.value)
  })
}
</script>

<style lang="scss">
.accordion-item {
  border-bottom: 1px solid $gray-5;

  &__header {
    @include flex-container(row, space-between);
    cursor: pointer;

    &--title {
      @include kaia-typography-generic(21px, 27px, 600);
      @include margin(md, bottom);
      color: $midnight;
    }
    &--trigger {
      @include margin(0);
      color: $midnight;
      align-self: center;
      transition: transform 0.25s linear;

      &.open {
        transform: rotate(-180deg);
      }
    }
  }

  &__content {
    color: $gray-50;
    @include kaia-typography-generic(17px, 23px, 400);
    overflow: hidden;
  }
}

.accordion-enter-active {
  max-height: 1000px;
}

.accordion-leave-active,
.accordion-enter-active {
  transition: all 0.35s ease;
}

.accordion-enter-from,
.accordion-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
