<template>
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75054 14.0145L2.56608 8.44912C2.26184 8.04448 1.68717 7.96309 1.28253 8.26733C0.877882 8.57157 0.796492 9.14624 1.10074 9.55088L5.9253 15.9675C6.26223 16.4157 6.91894 16.459 7.31186 16.0591L20.8206 2.30909C21.1754 1.94795 21.1703 1.36757 20.8092 1.01278C20.448 0.657976 19.8676 0.663112 19.5128 1.02425L6.75054 14.0145Z"
      fill="currentColor"
    />
  </svg>
</template>
