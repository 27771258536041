<template>
  <div
    ref="banner"
    class="base-banner"
    :class="[variant]"
    :data-qa="dataQA"
  >
    <div
      v-if="title || hasSlot['title']"
      class="base-banner__title"
    >
      <slot name="title">
        <p class="kds-typography-title-medium">{{ title }}</p>
      </slot>
    </div>
    <div
      v-if="description || hasSlot['default']"
      class="base-banner__description"
    >
      <slot>
        <p class="kds-typography-paragraph-medium">{{ description }}</p>
      </slot>
    </div>
    <div
      v-if="hasSlot['actions']"
      class="base-banner__actions"
    >
      <slot name="actions" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import useVueSlots from '@shared/composables/useVueSlots.js'

const { hasSlot } = useVueSlots(['default', 'actions', 'title'])
const props = defineProps({
  variant: {
    type: String,
    default: 'success',
    validator: (val) =>
      ['success', 'warning', 'important', 'info'].includes(val),
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  dataQA: {
    type: String,
    default: '',
  },
  scrollIntoView: {
    type: Boolean,
    default: false,
  },
})

const banner = ref(null)

onMounted(() => {
  if (props.scrollIntoView) {
    banner.value.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
})
</script>

<!-- WARN: Do not put 'scoped' here. Adding scoped prevents the use of the CSS variables in other locations. -->
<style lang="scss">
:root {
  --base-banner--background-color: #{$kds-color-background-green-subtle};
  --base-banner--color: #{$kds-color-text-primary};
  --base-banner--margin: #{map-get($spacing-sizes, lg) 0};
  --base-banner--padding: #{map-get($spacing-sizes, lg)};
}

.base-banner {
  background: var(--base-banner--background-color);
  color: var(--base-banner--color);
  border-radius: map-get($spacing-sizes, md);
  margin: var(--base-banner--margin);
  padding: var(--base-banner--padding);
  @include flex-container(column, flex-start, sm);

  &.info {
    --base-banner--background-color: #{$kds-color-background-blue-subtle};
  }

  &.important {
    --base-banner--background-color: #{$kds-color-background-yellow-subtle};
  }

  &.warning {
    --base-banner--background-color: #{$kds-color-background-magenta-subtle};
  }

  &__title {
    display: flex;
    justify-content: flex-start;
  }

  &__actions {
    @include flex-container(column, flex-start, sm, flex-start);
  }
}
</style>
