<template>
  <div class="simple-start-journey">
    <div class="simple-start-journey__info">
      <SimpleLandingLogo :corporate="corporate" />
      <div class="simple-start-journey__info--header">
        <h2 class="simple-start-journey__info--header--primary">
          {{ $t('b2b__simple_landing_page__start_journey__title') }}
        </h2>
        <h2 class="simple-start-journey__info--header--secondary">
          {{ $t('b2b__simple_landing_page__start_journey__slogan') }}
        </h2>
      </div>
      <span class="simple-start-journey__info--description">
        {{ $t('b2b__simple_landing_page__start_journey__description') }}
      </span>
      <BaseButton
        :text="ctaBtnTitle"
        @click="handleCTAButtonClick"
      />
      <SimpleLandingFooter
        :corporate="corporate"
        :lang="lang"
      />
    </div>

    <div class="simple-start-journey__banner" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseButton from '@shared/components/BaseButton.vue'
import SimpleLandingFooter from '@msk-us/components/SimpleLandingFooter.vue'
import SimpleLandingLogo from '@msk-us/components/SimpleLandingLogo.vue'
import Corporate from '@shared/data/models/corporate.js'

export default {
  name: 'SimpleStartJourney',
  components: {
    BaseButton,
    SimpleLandingFooter,
    SimpleLandingLogo,
  },
  props: {
    ctaBtnTitle: {
      type: String,
      default: '',
    },
    corporate: {
      type: Corporate,
      default: null,
    },
    handleCTAButtonClick: {
      type: Function,
      default: () => {},
    },
    isGerman: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('b2b', ['corporate']),
    ...mapGetters(['lang']),
  },
}
</script>

<style lang="scss">
.simple-start-journey {
  width: 100%;
  background-color: $white;

  @include grid-container(1fr, 1fr 400px, 0);
  grid-template-areas: 'info' 'banner';

  @include media-up(lg) {
    @include grid-container(1fr 1fr, auto, 0);
    grid-template-areas: 'info banner';
  }

  &__info {
    grid-area: info;
    @include flex-container(column, center);
    padding: 24px;

    @include media-up(xl) {
      padding: 24px 130px;
    }

    .simple-landing-logo {
      margin-bottom: 96px;
    }

    &--header {
      @include flex-container(column, flex-start, 0);

      &--primary {
        margin: 0;
        color: $text-primary;
        @include kaia-typography-h5($font-weight-bold);

        @include media-up(md) {
          @include kaia-typography-h4($font-weight-bold);
        }
      }

      &--secondary {
        margin: 0;
        color: $text-interactive-accent;

        @include media-up(md) {
          @include kaia-typography-h4($font-weight-bold);
        }
      }
    }

    &--description {
      @include kaia-typography-p2($font-weight-normal);
      margin-top: 24px;
      color: $text-secondary;

      @include media-up(md) {
        @include kaia-typography-p3($font-weight-normal);
      }
    }
  }

  &__banner {
    grid-area: banner;
    width: 100%;
    min-height: 240px;
    display: block;
    background: url('@msk-us/assets/img/thomas-male-banner.jpg') center top
      no-repeat;
    background-size: auto 100%;
  }
}
</style>
