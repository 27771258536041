import env from '@shared/env.js'

const stage = [
  {
    key: 'mocked-idp',
    title: 'Mocked IDP (SSO)',
  },
  {
    key: 'holcimus',
    title: 'LafargeHolcim',
    migrationDate: '2024-11-04 00:00:00.000000',
    sponsorId: '4583',
  },
  {
    key: 'personifyhealth',
    title: 'Personify Health (Pilot env)',
    migrationDate: '2024-10-15 00:00:00.000000',
    sponsorId: '28856',
  },
  {
    key: 'sebi',
    title: 'sebi test corp',
    migrationDate: '2024-11-04 00:00:00.000000',
    sponsorId: '999999',
  },
  {
    key: 'virginpulse',
    title: 'Virgin Pulse',
    migrationDate: '2024-11-04 00:00:00.000000',
    sponsorId: '24025',
  },
]

const prod = [
  {
    key: '3dsdassaultsystems',
    title: '3DS Dassault Systems',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '8131',
  },
  {
    key: 'acushnet',
    title: 'Acushnet Company',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '7202',
  },
  {
    key: 'amadeus',
    title: 'Amadeus North America Inc.',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '6147',
  },
  {
    key: 'ameritas',
    title: 'Ameritas Life Insurance',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '4813',
  },
  {
    key: 'cherry',
    title: 'Cherry Bekaert LLP',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5242',
  },
  {
    key: 'cheyennermc',
    title: 'Cheyenne Regional Medical Center',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '6220',
  },
  {
    key: 'choc',
    title: "Children's Hospital of Orange County",
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '6230',
  },
  {
    key: 'cohu',
    title: 'Cohu Inc',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5629',
  },
  {
    key: 'commonspirit',
    title: 'Common Spirit',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '8209',
  },
  {
    key: 'emergentbiosolutions',
    title: 'Emergent BioSolutions',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '4810',
  },
  {
    key: 'experian',
    title: 'Experian Information Solutions Inc.',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5263',
  },
  {
    key: 'golden1creditunion',
    title: 'Golden 1 Credit Union',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '8857',
  },
  {
    key: 'helenoftroy',
    title: 'Helen of Troy',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5451',
  },
  {
    key: 'higginbotham',
    title: 'Higginbotham',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5295',
  },
  {
    key: 'holcimus',
    title: 'Holcim US and Building Envelope',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '4583',
  },
  {
    key: 'hycite',
    title: 'Hy Cite Enterprises',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5215',
  },
  {
    key: 'hypertherm',
    title: 'Hypertherm',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '2963',
  },
  {
    key: 'ibew313',
    title: 'IBEW 313',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '8261',
  },
  {
    key: 'interdigital',
    title: 'InterDigital',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '6585',
  },
  {
    key: 'johnsonfinancialgroup',
    title: 'Johnson Financial Group',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '4829',
  },
  {
    key: 'lincolnelectricsystem',
    title: 'Lincoln Electric System',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5766',
  },
  {
    key: 'mercurysystems',
    title: 'Mercury Systems',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '6744',
  },
  {
    key: 'midcontinentmedia',
    title: 'Midcontinent Media',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '4529',
  },
  {
    key: 'mozilla',
    title: 'Mozilla Corporation',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '8227',
  },
  {
    key: 'personifyhealth',
    title: 'Personify Health (FKA Virgin Pulse)',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '2',
  },
  {
    key: 'personifyhealth-test',
    migrationDate: '2024-11-04 00:00:00.000000',
    sponsorId: '11795',
  },
  {
    key: 'pimacounty',
    title: 'Pima County',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '8388',
  },
  {
    key: 'renaissancelearning',
    title: 'Renaissance Learning',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5161',
  },
  {
    key: 'sita',
    title: 'Sita SC',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '2882',
  },
  {
    key: 'uofm',
    title: 'University of Minnesota',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5803',
  },
  {
    key: 'ventura',
    title: 'Ventura Foods LLC',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5632',
  },
  {
    key: 'virginpulse',
    title: 'Virgin Pulse',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '5202',
  },
  {
    key: 'wipfli',
    title: 'Wipfli LLP',
    migrationDate: '2026-01-01 00:00:00.000000',
    sponsorId: '7731',
  },
]

const corporateMap = env.VITE_MODE === 'prod' ? prod : stage

/**
 * @param corporateKey the corporate key to check the migration date
 * @returns {boolean} whether the corporate is already migrated (false if the corporate is not in the list)
 */
const isMigrated = (corporateKey) => {
  const migrationDate = corporateMap.find(
    (el) => el.key === corporateKey,
  )?.migrationDate
  return !!migrationDate && new Date(migrationDate) < Date.now()
}

/**
 * @returns { Boolean } whether all corporates are NOT YET migrated
 */
const noneAreMigrated = () =>
  corporateMap.filter((el) => !!el.title).every((el) => !isMigrated(el.key))

/**
 * @returns { Boolean } whether all corporates are ALREADY migrated
 */
const allAreMigrated = () =>
  corporateMap.filter((el) => !!el.title).every((el) => isMigrated(el.key))

export default { corporateMap, isMigrated, noneAreMigrated, allAreMigrated }
