<template>
  <main class="kaia-container">
    <section
      class="media-wrapper"
      :style="wrapperStyle"
    >
      <div class="app--logo-wrapper">
        <img
          src="@shared/assets/img/logo.svg"
          alt="logo"
          class="app--logo show-desktop"
        />
        <img
          src="@shared/assets/img/logo-mobile.svg"
          alt="logo"
          class="app--logo show-mobile"
        />
        <h6 v-html="$t('b2c_we_have_got_your_back')" />
      </div>
      <component
        :is="media"
        class="media-image"
      ></component>
    </section>
    <section class="content-wrapper">
      <router-view
        :key="$router.name"
        :color="color"
        :media="media"
      ></router-view>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Layout',
  data() {
    return {
      color: null,
      media: 'div',
    }
  },
  computed: {
    wrapperStyle() {
      return this.color
        ? { backgroundColor: this.color, transition: 'background-color .2s' }
        : {}
    },
  },
}
</script>

<style lang="scss">
.app {
  &--logo-wrapper {
    position: absolute;
    top: 50px;
    left: 80px;
    display: flex;
    align-self: flex-start;
    z-index: 1;
  }
  &--logo {
    width: 100px;
    height: 100px;
    margin-right: 24px;
    &.show-mobile {
      display: none;
    }
  }
}
.kaia-container {
  flex-direction: row;
  justify-content: stretch;
  width: 100%;
  .content-wrapper {
    flex: 2 0 auto;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    .content {
      width: 480px;
      margin: 70px 100px 70px 130px;
      height: auto;
    }
  }
  .media-wrapper {
    display: flex;
    flex: 1 1 720px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    transition: none;
    .media-image {
      position: relative;
      min-width: 75%;
      height: 100%;
      max-height: 100%;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        max-height: 100%;
      }
    }
  }
  .content-text {
    margin-bottom: 32px;
    p {
      font-size: 21px;
      letter-spacing: -0.24px;
      line-height: 32px;
      color: $gray-500;
      margin: 10px 0;
    }
  }
}
@media (max-width: $breakpoint-tablet) {
  .kaia-container {
    flex-direction: column;
    .media-wrapper {
      flex: 1 1 70px;
      max-height: 70px;
      padding: 17px 23px;
      height: auto;
      box-sizing: border-box;
      .media-image {
        display: none;
      }
      h6 {
        margin: 0;
      }
      .app--logo-wrapper {
        position: relative;
        top: 0;
        left: 0;
        width: 480px;
        margin: 0 auto;
        align-items: flex-end;
        justify-content: space-between;
      }
      .app--logo {
        &.show-mobile {
          display: block;
          width: 85px;
          height: 34px;
        }
        &.show-desktop {
          display: none;
        }
      }
    }
    .content-wrapper {
      height: auto;
      width: 100%;
      flex: 1 1 auto;
      .content {
        margin: 32px auto 57px;
        box-sizing: border-box;
      }
    }
  }
}
@media (max-width: $breakpoint-mobile) {
  .kaia-container {
    .media-wrapper {
      width: 100%;
      .app--logo-wrapper {
        width: 100%;
      }
    }
    .content-wrapper {
      height: auto;
      width: 100%;
      flex: 1 1 auto;
      .content {
        padding: 0 24px;
        width: auto;
      }
    }
    .content-text {
      margin-bottom: 18px;
      p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: initial;
      }
    }
  }
}
</style>
