<template>
  <div class="dropdown-list">
    <div
      ref="selectedItemRef"
      :class="[
        'dropdown-list__selected-item',
        showChevron ? 'dropdown-list__selected-item--with-chevron' : '',
      ]"
      @click="handleSelectedItemClickEvent"
    >
      <slot
        name="selected"
        v-bind="{
          item: selectedItemComputed,
          open: handleSelectedItemClickEvent,
        }"
      >
        <!-- use item slot as fallback for selected slot -->
        <slot
          name="item"
          v-bind="selectedItemComputed"
        />
      </slot>
    </div>
    <div
      v-if="showItems"
      ref="itemsRef"
      :class="itemsCSSClassesComputed"
    >
      <slot
        v-for="item in items"
        name="item"
        v-bind="item"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, ref } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  itemsLayoutCSSClasses: {
    type: Array,
    default: () => [],
  },
  defaultValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showChevron: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['change'])

const selectedItemRef = ref(null)
const itemsRef = ref(null)
const currentValue = ref(props.defaultValue)
const showItems = ref(false)
const selectedItemComputed = computed(
  () =>
    props.items.filter((i) => i.id === currentValue.value)[0] ?? props.items[0],
)
const itemsCSSClassesComputed = computed(() => [
  'dropdown-list-items',
  ...props.itemsLayoutCSSClasses,
])

const repositionDropdown = () => {
  const targetRect = selectedItemRef.value.getBoundingClientRect()
  const popupRect = itemsRef.value.getBoundingClientRect()
  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight
  const padding = 8

  let top = targetRect.top
  let left = targetRect.left

  // Adjust position if the popup exceeds the viewport
  if (top + popupRect.height > viewportHeight - padding) {
    top = viewportHeight - popupRect.height - padding
  }
  if (top < padding) top = padding

  if (left + popupRect.width > viewportWidth - padding) {
    left = viewportWidth - popupRect.width - padding
  }
  if (left < padding) left = padding

  itemsRef.value.style.top = `${top}px`
  itemsRef.value.style.left = `${left}px`
}

const handleSelectedItemClickEvent = () => {
  if (props.disabled || showItems.value) {
    return
  }

  showItems.value = true
  nextTick(repositionDropdown)

  const handleClickEvent = () => {
    document.addEventListener('click', handleItemsClickEvent)
    document.removeEventListener('click', handleClickEvent)
  }

  document.addEventListener('click', handleClickEvent)
}

const handleItemsClickEvent = (event) => {
  let element = event.target
  let dataValue = element.getAttribute('data-value')

  while (dataValue == null) {
    element = element.parentElement
    if (element == null) {
      break
    }

    dataValue = element.getAttribute('data-value')
  }

  showItems.value = false
  document.removeEventListener('click', handleItemsClickEvent)

  if (dataValue != null) {
    currentValue.value = dataValue
    emit('change', dataValue)
  }
}

onBeforeUnmount(() => {
  document.removeEventListener('click', handleItemsClickEvent)
})
</script>

<style lang="scss">
.dropdown-list {
  position: relative;

  &-items {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }

  &__selected-item {
    display: inline-block;
    //position: relative;
    max-width: 100%;

    &--with-chevron {
      padding-right: 16px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -3px;
        border: 6px solid transparent;
        border-top-color: $ocean;
        border-radius: 3px;
      }
    }
  }
}
</style>
