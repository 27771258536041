import { ref, computed, onMounted, onBeforeUnmount } from 'vue'

export default function useResponsiveness() {
  const screenWidth = ref(window.innerWidth)

  const size = computed(() => {
    if (screenWidth.value <= 375) {
      return 'sm'
    } else if (screenWidth.value <= 768) {
      return 'md'
    } else if (screenWidth.value <= 1024) {
      return 'lg'
    } else {
      return 'xl'
    }
  })

  const handleResize = () => {
    screenWidth.value = window.innerWidth
  }

  onMounted(() => {
    window.addEventListener('resize', handleResize)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
  })

  return {
    screenWidth,
    size,
  }
}
